import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={styles.Service}>
        <div className={cn("row", styles.Row)}>
          <div className="col-md-6">
            <div className={styles.Info}>
              <Title type="h2" as="h1" className={styles.Title}>
                Составление трудовых договоров
              </Title>
              <p className={styles.Text}>
                Трудовой договор нужен, чтобы в отношениях между работником и работодателем была
                стабильность и защищенность. Работодатель может быть уверен, что, пока действует
                договор, на его предприятии будет кому работать. Причем у этого человека есть
                образование, квалификация и умения, которые нужны для работы.
              </p>
              <p className={styles.Text}>
                Работник может быть уверен, что он будет стабильно получать зарплату, работать и
                отдыхать в соответствии с нормами законодательства.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.Image}>
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
