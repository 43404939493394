import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import styles from "./index.module.css"

export const FinalStatements = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className="row">
        <div className={cn("col-md-6", styles.Col)}>
          <Title type="h4" className={styles.Subtitle}>
            7. Трансграничная передача данных
          </Title>
          <p className={styles.Text}>
            7.1. Оператор до начала осуществления трансграничной передачи персональных данных обязан
            убедиться в том, что иностранным государством, на территорию которого предполагается
            осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов
            персональных данных.
          </p>
          <p className={styles.Text}>
            7.2. Трансграничная передача персональных данных на территории иностранных государств,
            не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия
            согласия в письменной форме субъекта персональных данных на трансграничную передачу его
            персональных данных и/или исполнения договора, стороной которого является субъект
            персональных данных.
          </p>
        </div>
        <div className={cn("col-md-6", styles.Col)}>
          <Title type="h4" className={styles.Subtitle}>
            8. Заключительные положения
          </Title>
          <p className={styles.Text}>
            8.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся
            обработки его персональных данных, обратившись к Оператору с помощью электронной почты
            info@bizhouse.ru.
          </p>
          <p className={styles.Text}>
            8.2. В данном документе будут отражены любые изменения политики обработки персональных
            данных Оператором. Политика действует бессрочно до замены ее новой версией.
          </p>
          <p className={styles.Text}>
            8.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по
            адресу httpsː//bizhouse.ru/policy.
          </p>
        </div>
      </div>
    </div>
  </div>
)
