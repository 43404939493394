import React from "react"
import { Helmet } from "react-helmet"

import { Feedback } from "~/components/Feedback/Feedback"
import { More } from "~/components/More/More"

import { Alternative } from "./components/Alternative/Alternative"
import { Hero } from "./components/Hero/Hero"
import { Price } from "./components/Price/Price"
import { Steps } from "./components/Steps/Steps"

export const LiquidationCompany = () => (
  <>
    <Helmet>
      <title>Ликвидация ООО</title>
      <meta
        name="description"
        content="Альтернативная и официальная ликвидация ООО – быстро подберем способ и полностью сопроводим процесс ликвидации вашей компании."
      />
    </Helmet>
    <Hero />
    <Steps />
    <Price />
    <Alternative />
    <More />
    <Feedback />
  </>
)
