import { useEffect, useState } from "react"
import ReactDOM from "react-dom"

export const Portal = ({ el = "#modal", children }) => {
  const [portalElement, updateState] = useState()

  useEffect(() => updateState(document.querySelector(el)), [])

  if (portalElement === undefined) {
    return null
  }

  return ReactDOM.createPortal(children, portalElement)
}
