import React from "react"
import { Helmet } from "react-helmet"

import { Feedback } from "~/components/Feedback/Feedback"
import { More } from "~/components/More/More"

import { Documents } from "./components/Documents/Documents"
import { Hero } from "./components/Hero/Hero"
import { Warning } from "./components/Warning/Warning"

export const LiquidationIndividual = () => (
  <>
    <Helmet>
      <title>Ликвидация ИП</title>
      <meta name="description" content="Комплексная ликвидация ИП." />
    </Helmet>
    <Hero />
    <Warning />
    <Documents />
    <More />
    <Feedback />
  </>
)
