import cn from "classnames"
import React from "react"

import rekvizity from "~/assets/rekvizity.pdf"
import { LazyImage } from "~/components/LazyImage/LazyImage"
import { Title } from "~/components/Title/Title"

import icon1 from "./assets/icon1.svg"
import icon2 from "./assets/icon2.svg"
import icon3 from "./assets/icon3.svg"
import icon4 from "./assets/icon4.svg"
import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Hero = () => {
  const items = [
    { icon: icon1, text: "Россия, г. Екатеринбург, <br/>ул. Бажова д. 193, оф. 401" },
    { icon: icon2, text: "Email: <a href='mailto:info@bizhouse.ru'>info@bizhouse.ru</a>" },
    { icon: icon3, text: "<a href='tel:+78002220236'>8 (800) 222-02-36</a>" },
    { icon: icon4, text: "Пн-Пт с 9:00 до 17:00 <br/>Сб – по договоренности" },
  ]
  return (
    <div className={cn("container", styles.Container)}>
      <div className={styles.Wrapper}>
        <div className={cn("row", styles.Row)}>
          <div className={styles.InfoWrapper}>
            <div className={styles.Info}>
              <Title type="h2" as="h1" className={styles.Title}>
                Наши контакты
              </Title>
              <div className={styles.List}>
                {items.map(({ icon, text }, i) => (
                  <div className={styles.Item} key={i}>
                    <img src={icon} alt="" className={styles.Icon} />
                    <span className={styles.Text} dangerouslySetInnerHTML={{ __html: text }} />
                  </div>
                ))}
              </div>
              <a
                href={rekvizity}
                className={styles.Requisites}
                target="_blank"
                rel="noopener noreferrer"
              >
                Наши реквизиты
              </a>
            </div>
          </div>
          <div className={cn("col-6", styles.ImageWrapper)}>
            <div className={styles.Image}>
              <LazyImage src={image} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
