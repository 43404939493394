import React from "react"
import YouTube from "react-youtube"

import { Popup } from "~/components/Popup/Popup"

import styles from "./index.module.css"

export const VideoPopup = ({ closeHandler }) => {
  const videoId = "zUOFvMzuHi4"

  const opts = {
    playerVars: {
      autoplay: 1,
    },
  }

  const onReady = (e) => {
    e.target.playVideo()
  }

  return (
    <Popup closeHandler={closeHandler} closeClassName={styles.VideoPopupClose}>
      <div
        className={styles.VideoContainer}
        style={{
          background: `url(https://img.youtube.com/vi/${videoId}/hqdefault.jpg) no-repeat center`,
          backgroundSize: "cover",
        }}
      >
        <YouTube videoId={videoId} opts={opts} onReady={onReady} />;
      </div>
    </Popup>
  )
}
