import React from "react"

import image from "./assets/image.jpg"
import styles from "./index.module.css"

export const Experience = () => (
  <div className={styles.Wrapper}>
    <div className="row">
      <div className="col-md-6 order-md-2">
        <div className={styles.Info}>
          <p className={styles.Text}>
            Оказывая юридические и&nbsp;бухгалтерские услуги для предпринимателей и&nbsp;организаций
            в соответствии с&nbsp;требованиями действующего законодательства, мы&nbsp;регулярно
            проводим мониторинг всевозможных правовых нововведений, чтобы своевременно внести
            необходимые коррективы и&nbsp;гарантировать качественное бухгалтерское обслуживание.
          </p>
          <p className={styles.Text}>
            Наши специалисты имеют внушительный опыт работы, поэтому, начав сотрудничество
            с&nbsp;нашей компанией, вы&nbsp;можете рассчитывать на&nbsp;первоклассное обслуживание,
            достоверность и исключительную точность выполненных работ. Все документы будут готовы
            в&nbsp;установленные сроки.
          </p>
          <p className={styles.Text}>
            Вам нужна грамотно выполненная бухгалтерская и&nbsp;налоговая отчетность? Нет времени
            искать достойного специалиста? Обращайтесь в&nbsp;нашу компанию, ведь мы&nbsp;&mdash;
            это высококвалифицированный персонал, ориентирующийся во&nbsp;всех тонкостях налогового
            законодательства; строгое соблюдение сроков сдачи работ и&nbsp;разумные цены
            на&nbsp;услуги!
          </p>
        </div>
      </div>
      <div className="col-md-6 order-md-1">
        <div className={styles.Image}>
          <img src={image} alt="" />
        </div>
      </div>
    </div>
  </div>
)
