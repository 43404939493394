import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image1 from "./assets/image1.svg"
import image2 from "./assets/image2.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={styles.Service}>
        <div className={cn("row", styles.Row)}>
          <div className="col-md-6">
            <div className={styles.Info}>
              <Title type="h2" as="h1" className={styles.Title}>
                Бизнес консультации
              </Title>
              <p>
                Профессиональная помощь консультанта в&nbsp;исследовании, разработке
                и&nbsp;реализации бизнес задач, стоящих перед предпринимателем.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.Image}>
              <img src={image1} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 order-md-2">
          <div className={styles.ProcessInfo}>
            <Title type="h3">Что это такое?</Title>
            <p className={styles.Text}>
              Бизнес консультация позволяет рассмотреть вопрос под различными углами, найти новые
              идеи и эффективные варианты их решения.
            </p>
            <p className={styles.Text}>
              Мы занимаемся консультированием и последующим сопровождением по различным вопросам
              бизнеса: от регистрации и выбора системы налогообложения до решения финансовых задач
              по кредитованию и пополнению оборотных активов. Предоставляем экспертную оценку по
              ведению бизнеса, даем рекомендации, оказываем практическую помощь и осуществляем
              поддержку на всех этапах.
            </p>
          </div>
        </div>
        <div className="col-md-6 order-md-1">
          <div className={cn(styles.Image, styles.Image2)}>
            <img src={image2} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
