import React from "react"

import { Title } from "~/components/Title/Title"

import styles from "./index.module.css"

export const Benefit = () => {
  const items = [
    "Вы&nbsp;сможете избежать стандартных ошибок при работе с&nbsp;банком, приводящих в&nbsp;проверке по&nbsp;115-ФЗ",
    "Вы&nbsp;будете знать, как вести себя при вызове на&nbsp;допрос в&nbsp;ФНС",
    "Сможете выстраивать схемы работы с&nbsp;несколькими банками",
    "Поймете, каким способом законно получать наличные в&nbsp;банке под минимальные проценты",
    "Сформируете понимание того, как именно работает банковская система для исполнения требований 115-ФЗ",
    "Вы&nbsp;разберетесь в&nbsp;системах налогообложения и&nbsp;сможете правильно оптимизировать налоговую нагрузку",
  ]

  return (
    <div className={styles.Container}>
      <div className={styles.Wrapper}>
        <Title type="h3" className={styles.Title}>
          {"Что вы получите, <br /> пройдя курс обучения"}
        </Title>
        <div className={styles.Row}>
          {items.map((text, i) => (
            <div className={styles.ItemWrapper} key={i}>
              <div className={styles.Item}>
                <span className={styles.Number}>0{i + 1}</span>
                <p className={styles.Text} dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
