import React from "react"
import { Helmet } from "react-helmet"

import { Experience } from "./components/Experience/Experience"
import { Hero } from "./components/Hero/Hero"
import { Responsibility } from "./components/Responsibility/Responsibility"
import { Trust } from "./components/Trust/Trust"

export const About = () => (
  <>
    <Helmet>
      <title>О компании</title>
      <meta
        name="description"
        content="Комплексный аутсорсинг бухгалтерских и юридических услуг с 2010 года."
      />
    </Helmet>
    <div className="container">
      <Hero />
      <Experience />
      <Trust />
      <Responsibility />
    </div>
  </>
)
