import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image1 from "./assets/image1.svg"
import image2 from "./assets/image2.svg"
import styles from "./index.module.css"

export const Responsibility = () => (
  <div className={styles.Wrapper}>
    <div className={styles.Responsibility}>
      <div className="row">
        <div className="col-md-6">
          <div className={styles.Info}>
            <Title type="h4">Письменные обязательства возмещения убытков</Title>
            <p className={styles.Text}>
              При заключении договора на&nbsp;бухгалтерское обслуживание, мы&nbsp;принимаем
              на&nbsp;себя письменные обязательства возмещения убытков нашего клиента в&nbsp;100%
              размере, возникших вследствие неточности или ошибки нашего сотрудника.
            </p>
            <p className={styles.Text}>
              При завершении работы ответственного бухгалтера с&nbsp;документацией клиента,
              результаты его работы обязательно проверяет другой сотрудник. Это система, так
              называемого, &laquo;двойного контроля&raquo;. Ее&nbsp;применение позволяет нам свести
              до&nbsp;нуля количество неточностей и&nbsp;ошибок.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className={styles.Image}>
            <img src={image1} alt="" />
          </div>
        </div>
      </div>
    </div>
    <div className={styles.Reason}>
      <div className="row">
        <div className="col-md-6 order-md-2">
          <div className={styles.Info}>
            <Title type="h4">
              Ни&nbsp;одна компания не&nbsp;сможет существовать без услуг бухгалтера
            </Title>
            <p className={styles.Text}>
              Руководителю просто некогда заниматься вопросами оформления бумаг, иначе не&nbsp;будет
              времени работать. Ведение бухгалтерского учета дело простое, но&nbsp;для тех, кто не
              разбирается в&nbsp;этом, данный вид деятельности покажется мучением. В&nbsp;тоже время
              раз государственные органы обязывают это делать, выхода нет, придется учиться или
              обращаться к&nbsp;специалистам.
            </p>
            <p className={styles.Text}>
              Как показывает практика дешевле обратиться к&nbsp;специалистам. Экономя несколько
              тысяч рублей, тратя время на&nbsp;обучение бухгалтерскому учету, а&nbsp;главное
              большая вероятность допустить ошибку и&nbsp;получить за&nbsp;это штраф или проверку
              от&nbsp;государственных ведомств&nbsp;&mdash; не лучшая идея.
            </p>
          </div>
        </div>
        <div className="col-md-6 order-md-1">
          <div className={cn(styles.Image, styles.Image2)}>
            <img src={image2} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
