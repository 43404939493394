import React from "react"
import { CSSTransition } from "react-transition-group"

import styles from "./index.module.css"

export const ImageWrapper = ({ isVisible, src }) => (
  <CSSTransition
    in={isVisible}
    unmountOnExit
    timeout={500}
    classNames={{
      enter: styles.ImageEnter,
      enterActive: styles.ImageEnterActive,
    }}
  >
    <img src={src} />
  </CSSTransition>
)
