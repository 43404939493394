import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import styles from "./index.module.css"

export const Content = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className="row">
        <div className={cn("col-md-6", styles.Col)}>
          <Title type="h4" className={styles.Subtitle}>
            3. Оператор может обрабатывать следующие персональные данные Пользователя
          </Title>
          <p className={styles.Text}>3.1. Фамилия, имя, отчество;</p>
          <p className={styles.Text}>3.2. Номер телефона;</p>
          <p className={styles.Text}>3.3. Адрес электронной почты;</p>
          <p className={styles.Text}>
            3.4. Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в
            т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл
            Аналитика и других);
          </p>
          <p className={styles.Text}>
            3.5. Вышеперечисленные данные далее по тексту Политики объединены общим понятием
            Персональные данные.
          </p>
        </div>
        <div className={cn("col-md-6", styles.Col)}>
          <Title type="h4" className={styles.Subtitle}>
            4. Цели обработки персональных данных
          </Title>
          <p className={styles.Text}>
            4.1. Цель обработки персональных данных Пользователя — заключение, исполнение и
            прекращение гражданско-правовых договоров; предоставление доступа Пользователю к
            информации и/или материалам, содержащимся на веб-сайте.
          </p>
          <p className={styles.Text}>
            4.2. Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и
            услугах, специальных предложениях и различных событиях. Пользователь всегда может
            отказаться от получения информационных сообщений, направив Оператору письмо на адрес
            электронной почты info@bizhouse.ru с пометкой «Отказ от уведомлений».
          </p>
          <p className={styles.Text}>
            4.3. Обезличенные данные Пользователей, собираемые с помощью сервисов
            интернет-статистики, служат для сбора информации о действиях Пользователей на сайте,
            улучшения качества сайта и его содержания.
          </p>
        </div>
      </div>
    </div>
  </div>
)
