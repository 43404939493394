import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"

import { ImageWrapper } from "./components/ImageWrapper/ImageWrapper"
import { PlaceholderWrapper } from "./components/PlaceholderWrapper/PlaceholderWrapper"
import styles from "./index.module.css"

export const LazyImage = ({
  src,
  placeholderSrc,
  className,
  withBg,
  placeholderColor,
  ...props
}) => {
  const offset = "250px"

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: `${offset} 0px ${offset} 0px`,
  })

  const [isLoaded, setLoadedState] = useState(false)
  const [isCached, setCachedState] = useState(false)

  useEffect(() => {
    if (!inView) {
      return
    }

    const img = new Image()
    img.src = src

    if (!img.complete) {
      img.onload = () => setLoadedState(true)
    } else {
      setCachedState(true)
    }
  }, [inView])

  if (isCached) {
    return (
      <div className={cn(styles.Wrapper, className)}>
        <img src={src} />
      </div>
    )
  }

  return (
    <div ref={ref} className={cn(styles.Wrapper, className)} {...props}>
      <PlaceholderWrapper
        withBg={withBg}
        placeholderColor={placeholderColor}
        src={placeholderSrc}
        isVisible={!isLoaded}
      />
      <ImageWrapper src={src} isVisible={isLoaded && inView} />
    </div>
  )
}
