import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image1 from "./assets/image1.svg"
import image2 from "./assets/image2.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={styles.Service}>
        <div className={cn("row", styles.Row)}>
          <div className="col-md-6">
            <div className={styles.Info}>
              <Title type="h2" as="h1" className={styles.Title}>
                Регистрация ИП за 3 простых шага
              </Title>
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.Image}>
              <img src={image1} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 order-md-2">
          <div className={styles.ProcessInfo}>
            <Title type="h3">Подготовка пакета документов</Title>
            <p className={styles.Text}>
              Для оформления полного пакета документов от вас потребуются только паспорт и СНИЛС.
              При необходимости, мы подскажем какую форму налогообложения лучше выбрать для ваших
              задач.
            </p>
          </div>
        </div>
        <div className="col-md-6 order-md-1">
          <div className={cn(styles.Image, styles.Image2)}>
            <img src={image2} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
