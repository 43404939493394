import React from "react"
import { CSSTransition } from "react-transition-group"

import styles from "./index.module.css"

export const PlaceholderWrapper = ({ src, isVisible, withBg, placeholderColor }) => (
  <CSSTransition
    in={isVisible}
    unmountOnExit
    timeout={500}
    classNames={{
      exit: styles.PlaceholderExit,
      exitActive: styles.PlaceholderExitActive,
    }}
  >
    <Placeholder src={src} withBg={withBg} placeholderColor={placeholderColor} />
  </CSSTransition>
)

const Placeholder = ({ src, withBg, placeholderColor = "#f4f4f4" }) => {
  if (src) {
    return (
      <div className={styles.ImagePlaceholder}>
        <img src={src} />
      </div>
    )
  }
  if (withBg) {
    return <div className={styles.ColorPlaceholder} style={{ backgroundColor: placeholderColor }} />
  }
  return null
}
