import React from "react"
import { Helmet } from "react-helmet"

import { Feedback } from "~/components/Feedback/Feedback"
import { More } from "~/components/More/More"

import { Hero } from "./components/Hero/Hero"
import { Price } from "./components/Price/Price"

export const LiabilityContract = () => (
  <>
    <Helmet>
      <title>Составление договоров о материальной ответственности</title>
      <meta
        name="description"
        content="Договор о полной коллективной и индивидуальной материальной ответственности является основанием для взыскания ущерба, причиненного сотрудниками орагнизации."
      />
    </Helmet>
    <Hero />
    <Price />
    <More />
    <Feedback />
  </>
)
