import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Alternative = () => (
  <div className={styles.Wrapper}>
    <div className="container">
      <div className={styles.Intro}>
        <div className={styles.IntroInner}>
          <Title type="h3" className={styles.Title}>
            Альтернативные способы ликвидации
          </Title>
          <p className={styles.Text}>
            Вся процедура ликвидации может занять от 4 до 6 месяцев. Сроки ликвидации зависят не
            только от ФНС, но и от состояния бухгалтерского учета ООО и наличия кредиторов на момент
            ликвидации.
          </p>
        </div>
      </div>
      <div className={cn("row", styles.Row)}>
        <div className={cn("col-md-6", styles.Col)}>
          <div className={cn(styles.Item)}>
            <img className={styles.Image} src={image} alt="" />
            <div className={styles.ItemInfo}>
              <div className={styles.ItemHead}>
                <div className={styles.ItemNumber}>01</div>
                <p className={styles.ItemTitle}>
                  Ликвидация через <br />
                  управляющую компанию
                </p>
              </div>
              <p className={styles.Text}>
                Вместо директора и&nbsp;учредителя ООО, которое нужно ликвидировать, ставится другое
                ООО (управляющая компания), которое проходит стандартную процедуру ликвидации через
                ФНС. После ликвидации управляющей компании, ФНС спустя один год автоматически
                исключает Ваше ООО из&nbsp;ЕГРЮЛ, так как управляющая компания, которой принадлежало
                Ваше ООО, больше не&nbsp;существует.
              </p>
              <p className={styles.Text}>
                Главное, используя такой способ &laquo;альтернативной&raquo; ликвидации, правильно
                подобрать управляющую компанию (чем и&nbsp;занимаются наши специалисты).
              </p>
            </div>
            <div className={styles.ItemAfterword}>
              <p className={styles.Text}>
                Процедура ликвидации управляющей компании занимает
                от&nbsp;4&nbsp;до&nbsp;6&nbsp;месяцев, после ликвидации УК&nbsp;Ваше ООО исключается
                из&nbsp;ЕГРЮЛ в&nbsp;течение одного года.
              </p>
              <p className={styles.Price}>
                Стоимость данной услуги глубоко индивидуальна, но&nbsp;часто не&nbsp;превышает
                300&nbsp;000 рублей.
              </p>
            </div>
          </div>
        </div>
        <div className={cn("col-md-6", styles.Col)}>
          <div className={cn(styles.Item)}>
            <div className={styles.ItemInfo}>
              <div className={styles.ItemHead}>
                <div className={styles.ItemNumber}>02</div>
                <p className={styles.ItemTitle}>
                  Ликвидация <br />
                  через оффшор
                </p>
              </div>
              <p className={styles.Text}>
                Большая часть доли в&nbsp;ООО продается иностранному учредителю, который должен
                внести в ООО активы. Стоимость активов указывается договоре купли-продажи доли
                в&nbsp;ООО. При внесении изменений в&nbsp;ЕГРЮЛ о&nbsp;наличии иностранного
                учредителя, ООО объявляет о ликвидации по&nbsp;упрощенной процедуре, это займет
                не&nbsp;более трех недель.
              </p>
            </div>
            <div className={styles.ItemAfterword}>
              <p className={styles.Text}>
                Альтернативный способ ликвидации имеет и&nbsp;недостатки. Основной минус закрытия
                ООО данным способом&nbsp;&mdash; риск, что процедура будет признана нелегальной.
                Часто налоговые инспекторы пытаются досконально изучить причины проведения
                ликвидации. Поэтому могут всплывать проблемы с&nbsp;финансово-хозяйственной
                деятельностью юридического лица.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
