import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image1 from "./assets/image1.svg"
import image2 from "./assets/image2.svg"
import image3 from "./assets/image3.svg"
import image4 from "./assets/image4.svg"
import styles from "./index.module.css"

export const Program = () => {
  const items = [
    {
      subtitle:
        "Этап регистрации ИП и ООО, выбор системы налогообложения, сложности с которыми можно столкнуться на данном этапе.",
      textArr: [
        "Как правильно выбрать организационно-правовую форму, ООО или ИП? В чем их отличие? Как сказывается выбор ОПФ на налоговой нагрузке? Какие требования предъявляет ФНС и банки к бизнесу в зависимости от ОПФ? Насколько важен достоверный юридический адрес для ООО и почему? Какими статьями ГК регулируется деятельность ООО и ИП? В чем принципиальная разница между двумя этими ОПФ?",
      ],
      image: image1,
    },
    {
      subtitle:
        "Системы налогообложения, налоговые ставки, налоговая нагрузка, законная оптимизация",
      textArr: [
        "Правильный выбор системы налогообложения – 50% успеха вашего бизнеса. Так же существуют налоговые льготы, в зависимости от того, какая система налогообложения вами выбрана. Особенности налогового и бухгалтерского учета каждой системы налогообложения.",
        "Обязанность сдачи отчетности. Контрольные мероприятия, которые проводит ФНС. Как и какую именно налоговую отчетность проверяет налоговый инспектор? Как поменять систему налогообложения?",
      ],
      image: image2,
    },
    {
      subtitle: "Взаимодействие с ФНС и банками, исходя их требований 115-ФЗ",
      textArr: [
        "На какие действия имеет право банк, если вы попали под проверку? Какие документы банк имеет право истребовать? Какими критериями отбора банк руководствуется при выборе контрагента на проверку? Как вести себя на допросе в налоговой инспекции? Какие вопросы имеет право задавать налоговый инспектор и на все ли вопросы вы обязаны отвечать? Как обезопасить себя и свой бизнес от последствий действия данного закона? Это лишь малый список вопросов, которые мы будем разбирать на третьем занятии.",
      ],
      image: image3,
    },
    {
      subtitle: "Труд наемных работников",
      textArr: [
        "Какие права и&nbsp;обязанности возникают у&nbsp;вас с&nbsp;того момента, как вы&nbsp;подписали первый трудовой договор? Какими нормативно-правовыми актами регулируются отношения между работником и&nbsp;работодателем? Какими документами закрепляются эти отношения. Какие государственные органы эти отношения контролируют и&nbsp;как они это делают? Прокуратура и&nbsp;трудовая инспекция: какими полномочиями для контроля наделена каждая из&nbsp;этих структур?",
      ],
      image: image4,
    },
  ]
  return (
    <div className={styles.Wrapper}>
      <div className="container">
        <Title type="h3">Программа курса</Title>
        {items.map(({ textArr, subtitle, image }, i) => (
          <div className={cn("row", styles.Row)} key={i}>
            <div className={cn("col-md-6", { "order-md-2": i % 2 === 0 })}>
              <div className={styles.Info}>
                <h5 className={styles.Title}>День {i + 1}</h5>
                <p className={styles.Subtitle}>{subtitle}</p>
                {textArr.map((paragraph, i) => (
                  <p
                    key={i}
                    className={styles.Text}
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                  />
                ))}
              </div>
            </div>
            <div className={cn("col-md-6", { "order-md-1": i % 2 === 0 })}>
              <div className={cn(styles.Image, styles[`Image${i + 1}`])}>
                <img src={image} alt="" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
