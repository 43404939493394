import React from "react"
import { Helmet } from "react-helmet"

import { Feedback } from "~/components/Feedback/Feedback"
import { Partners } from "~/components/Partners/Partners"

import { Advantages } from "./components/Advantages/Advantages"
import { Care } from "./components/Care/Care"
import { Hero } from "./components/Hero/Hero"
import { Services } from "./components/Services/Services"

export const Main = () => (
  <>
    <Helmet>
      <title>Главная</title>
      <meta
        name="description"
        content="Комплексное бухгалтерское и юридическое обслуживание бизнеса. Занимайтесь развитием вашего бизнеса — все остальное сделаем мы!"
      />
    </Helmet>
    <Hero />
    <Advantages />
    <Care />
    <Services />
    <Partners />
    <Feedback />
  </>
)
