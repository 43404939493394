import GoogleMapReact from "google-map-react"
import React from "react"

import { mapStyles } from "~/data/mapStyles"

import styles from "./index.module.css"

export const Map = () => {
  const center = { lat: 56.827163, lng: 60.6326708 }
  const renderMarker = (map, maps) => {
    return new maps.Marker({
      position: center,
      map,
    })
  }

  return (
    <div className={styles.MapContent} style={{ width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCAhJ77LmTfOGvd3rNebH2CEFPKge4OqN0" }}
        options={{
          styles: mapStyles,
          disableDefaultUI: true,
          zoomControl: true,
        }}
        defaultCenter={center}
        defaultZoom={17}
        onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
      />
    </div>
  )
}
