import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import bg from "~/assets/bg.svg"
import { Footer } from "~/components/Footer/Footer"
import { Header } from "~/components/Header/Header"
import { Loc } from "~/components/Loc/Loc"
import { NotFound } from "~/components/NotFound/NotFound"
import { About } from "~/views/About/About"
import { Accounting } from "~/views/Accounting/Accounting"
import { CivilContract } from "~/views/CivilContract/CivilContract"
import { Claims } from "~/views/Claims/Claims"
import { Complaints } from "~/views/Complaints/Complaints"
import { Consultation } from "~/views/Consultation/Consultation"
import { Contacts } from "~/views/Contacts/Contacts"
import { Contracts } from "~/views/Contracts/Contracts"
import { Course } from "~/views/Course/Course"
import { CourtRepresentation } from "~/views/CourtRepresentation/CourtRepresentation"
import { CourtStatements } from "~/views/CourtStatements/CourtStatements"
import { EmploymentContract } from "~/views/EmploymentContract/EmploymentContract"
import { InstitutionRepresentation } from "~/views/InstitutionRepresentation/InstitutionRepresentation"
import { LiabilityContract } from "~/views/LiabilityContract/LiabilityContract"
import { LiquidationCompany } from "~/views/LiquidationCompany/LiquidationCompany"
import { LiquidationIndividual } from "~/views/LiquidationIndividual/LiquidationIndividual"
import { Main } from "~/views/Main/Main"
import { Policy } from "~/views/Policy/Policy"
import { Prokuratura } from "~/views/Prokuratura/Prokuratura"
import { RegistrationCompany } from "~/views/RegistrationCompany/RegistrationCompany"
import { RegistrationIndividual } from "~/views/RegistrationIndividual/RegistrationIndividual"
import { StaffingSchedule } from "~/views/StaffingSchedule/StaffingSchedule"
import { WorkInspection } from "~/views/WorkInspection/WorkInspection"
import { WorkSchedule } from "~/views/WorkSchedule/WorkSchedule"

export const App = () => (
  <Router>
    <React.Fragment>
      <div
        className="content"
        style={{
          background: `url(${bg}) no-repeat top center`,
          backgroundSize: "cover",
          zIndex: 0,
          position: "relative",
        }}
      >
        <Loc />
        <Header />
        <Switch>
          <Route path={"/"} component={Main} exact />
          <Route path={"/contacts"} component={Contacts} exact />
          <Route path={"/about"} component={About} exact />
          <Route path={"/course"} component={Course} exact />
          <Route path={"/buhgalterskoe-obsluzhivanie"} component={Accounting} exact />
          <Route path={"/biznes-konsultacii"} component={Consultation} exact />
          <Route path={"/policy"} component={Policy} exact />
          <Route path={"/likvidaciya-ip"} component={LiquidationIndividual} exact />
          <Route path={"/likvidaciya-ooo"} component={LiquidationCompany} exact />
          <Route path={"/registraciya-ooo"} component={RegistrationCompany} exact />
          <Route path={"/registraciya-ip"} component={RegistrationIndividual} exact />
          <Route path={"/sostavlenie-pretenzij"} component={Claims} exact />
          <Route path={"/zhaloby-i-zayavleniya"} component={Complaints} exact />
          <Route path={"/sostavlenie-dogovorov"} component={Contracts} exact />
          <Route path={"/predstavitelstvo-v-sude"} component={CourtRepresentation} exact />
          <Route path={"/iskovye-zayavleniya"} component={CourtStatements} exact />
          <Route path={"/predstavitelstvo-v-organah"} component={InstitutionRepresentation} exact />
          <Route path={"/shtatnoe-raspisanie"} component={StaffingSchedule} exact />
          <Route path={"/prokuratura"} component={Prokuratura} exact />
          <Route path={"/trudovaya-inspekciya"} component={WorkInspection} exact />
          <Route path={"/trudovoj-dogovor"} component={EmploymentContract} exact />
          <Route path={"/trudovoj-rasporyadok"} component={WorkSchedule} exact />
          <Route path={"/dogovor-gph"} component={CivilContract} exact />
          <Route
            path={"/dogovor-materialnoj-otvetstvennosti"}
            component={LiabilityContract}
            exact
          />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </div>
    </React.Fragment>
  </Router>
)
