import cn from "classnames"
import React from "react"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Warning = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={styles.Inner}>
        <div className={cn("row", styles.Row)}>
          <div className="col-sm-6 col-md-5">
            <div className={styles.Image}>
              <img src={image} alt="" />
            </div>
          </div>
          <div className="col-sm-6 col-md-7">
            <div className={styles.Info}>
              <p className={styles.Title}>Важно!</p>
              <p className={styles.Text}>
                Следует помнить, что с&nbsp;закрытием&nbsp;ИП не&nbsp;прекращаются долговые
                обязательства предпринимателя, так как ИП&nbsp;&mdash; это обычное физическое лицо.
              </p>
              <p className={styles.Text}>
                Соответственно, неисполненные обязательства по&nbsp;уплате налогов, взносов,
                штрафов, начисленные в&nbsp;период работы&nbsp;ИП, будут взыскиваться с&nbsp;бывшего
                предпринимателя в&nbsp;общем порядке.
              </p>
              <p className={styles.Text}>
                Кредиторская задолженность, например, задолженность перед контрагентом или банком,
                будет взыскиваться через суд.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
