import React from "react"

import { Title } from "~/components/Title/Title"

import styles from "./index.module.css"

export const CollectData = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className="row">
        <div className="col-md-12">
          <Title type="h4" className={styles.Subtitle}>
            6. Порядок сбора, хранения, передачи и других видов обработки персональных данных
          </Title>
          <p className={styles.Text}>
            Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается
            путем реализации правовых, организационных и технических мер, необходимых для выполнения
            в полном объеме требований действующего законодательства в области защиты персональных
            данных.
          </p>
          <p className={styles.Text}>
            6.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные
            меры, исключающие доступ к персональным данным неуполномоченных лиц;
          </p>
          <p className={styles.Text}>
            6.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы
            третьим лицам, за исключением случаев, связанных с исполнением действующего
            законодательства;
          </p>
          <p className={styles.Text}>
            6.3. В случае выявления неточностей в персональных данных, Пользователь может
            актуализировать их самостоятельно, путем направления Оператору уведомление на адрес
            электронной почты Оператора info@bizhouse.ru с пометкой «Актуализация персональных
            данных»;
          </p>
          <p className={styles.Text}>
            6.4. Срок обработки персональных данных является неограниченным. Пользователь может в
            любой момент отозвать свое согласие на обработку персональных данных, направив Оператору
            уведомление посредством электронной почты на электронный адрес Оператора
            info@bizhouse.ru с пометкой «Отзыв согласия на обработку персональных данных».
          </p>
        </div>
      </div>
    </div>
  </div>
)
