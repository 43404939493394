import cn from "classnames"
import React from "react"
import { Link } from "react-router-dom"

import { Title } from "~/components/Title/Title"

import arrow from "./assets/arrow.svg"
import image from "./assets/image.svg"
import styles from "./index.module.css"

export const NotFound = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={cn("row", styles.Row)}>
        <div className="col-md-5">
          <div className={styles.Info}>
            <Title type={"h2"}>Страница не найдена</Title>
            <div className={styles.LinkWrapper}>
              <Link to={"/"} className={styles.Link}>
                Перейти на главную
              </Link>
              <img className={styles.Arrow} src={arrow} alt="" />
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className={styles.Image}>
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
