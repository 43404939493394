import React from "react"

import { Title } from "~/components/Title/Title"

import icon from "./assets/icon.svg"
import styles from "./index.module.css"

export const Trust = () => {
  const items = [
    "Документ, соответствующий всем текущим нормам",
    "Cвоевременную сдачу отчетности",
    "ПО для документооборота и постоянную техподдержку",
    "Конкурентоспособные цены на&nbsp;услуги",
    "Регулярные консультации по&nbsp;оптимизации системы налогообложения",
    "Своевременное информирование об&nbsp;изменениях в&nbsp;законодательстве",
  ]
  return (
    <div className={styles.Wrapper}>
      <Title type="h4">Доверившись нам вы получаете:</Title>

      <div className={styles.List}>
        {items.map((text, i) => (
          <div key={i} className={styles.Item}>
            <div className={styles.Icon}>
              <img src={icon} alt="" />
              <span className={styles.Number}>{"0" + (i + 1)}</span>
            </div>
            <span dangerouslySetInnerHTML={{ __html: text }} className={styles.Text} />
          </div>
        ))}
      </div>
    </div>
  )
}
