import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import styles from "./index.module.css"

export const Services = () => {
  const items = [
    "База 1С",
    "Ключ ЭЦП для сдачи отчетности",
    "Заведение первичной документации",
    "Формирование бухгалтерских регистров",
    "Сдача отчетности",
    "Формирование актов сверок",
    "Ответы на требования ФНС и банков",
    "Прохождение камеральных и выездных проверок",
    "Устные консультации бухгалтера",
    "Техподдержка ПО",
  ]

  return (
    <div className="container">
      <div className={styles.Wrapper}>
        <Title type="h3">{"Что вы получите, <br />обратившись к нам"}</Title>
        <div className={styles.Info}>
          <p className={styles.Text}>
            Все наши клиенты получают одинаково профессиональное обслуживание. Стоимость тарифа для
            вашей компании зависит исключительно от&nbsp;вашего оборота, количества сотрудников и
            дополнительных услуг. Также мы&nbsp;не&nbsp;разделяем компании по&nbsp;типу
            организационной формы. Вся отчетность сдается в сооствествии с&nbsp;системой
            налогообложения и&nbsp;актуальными требованиями законодательства.
          </p>
        </div>
        <div className={cn("row", styles.ListRow)}>
          <div className={cn("col-sm-6", styles.ListCol, styles.ListColFirst)}>
            <div className={styles.ListWrapper}>
              {items.map((text, i) => (
                <React.Fragment key={i}>
                  {i < 5 && <div className={styles.Item}>{text}</div>}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className={cn("col-sm-6", styles.ListCol, styles.ListColSecond)}>
            <div className={styles.ListWrapper}>
              {items.map((text, i) => (
                <React.Fragment key={i}>
                  {i > 4 && <div className={styles.Item}>{text}</div>}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
