import React from "react"
import { Link } from "react-router-dom"

import arrow from "./assets/arrow.svg"
import styles from "./index.module.css"

export const Services = () => {
  const items = [
    { title: "Регистрация ООО, АО", link: "/registraciya-ooo" },
    { title: "Регистрация ИП", link: "/registraciya-ip" },
    { title: "Ликвидация ООО", link: "/likvidaciya-ooo" },
    { title: "Ликвидация ИП", link: "/likvidaciya-ip" },
  ]

  return (
    <div className={styles.Container}>
      <div className={styles.Wrapper}>
        <div className={styles.Info}>
          <div className={styles.Row}>
            {items.map(({ title, link }, i) => (
              <div className={styles.ItemWrapper} key={i}>
                <div className={styles.Item}>
                  <Link to={link} className={styles.Link} />
                  <div className={styles.ItemInner}>
                    <span className={styles.Title}>{title}</span>
                  </div>
                  <div className={styles.Arrow}>
                    <img src={arrow} className={styles.ArrowIcon} alt="" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
