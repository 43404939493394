import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={styles.Service}>
        <div className={cn("row", styles.Row)}>
          <div className="col-md-6">
            <div className={styles.Info}>
              <Title type="h2" as="h1" className={styles.Title}>
                Составление претензий
              </Title>
              <p className={styles.Text}>
                Часто в бизнесе бывают задержки оплат от покупателей или задержка поставок от
                поставщиков. Для того, чтобы зафиксировать факт нарушения договорных условий ведется
                претензионная работа. Мы ознакомимся с документами и составим претензию, которая в
                будущем может пригодиться вам, как доказательство в суде.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.Image}>
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
