import React from "react"
import { Helmet } from "react-helmet"

import { Feedback } from "~/components/Feedback/Feedback"
import { More } from "~/components/More/More"
import { Partners } from "~/components/Partners/Partners"

import { Documents } from "./components/Documents/Documents"
import { Hero } from "./components/Hero/Hero"
import { Price } from "./components/Price/Price"
import { Way } from "./components/Way/Way"

export const RegistrationIndividual = () => (
  <>
    <Helmet>
      <title>Регистрация ИП</title>
      <meta
        name="description"
        content="Регистрация ИП за 3 дня! Без оплаты госпошлины и выезда к нотариусу."
      />
    </Helmet>
    <Hero />
    <Way />
    <Documents />
    <Price />
    <Partners />
    <More />
    <Feedback />
  </>
)
