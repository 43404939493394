import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className={styles.Wrapper}>
    <div className="container">
      <Title type="h2" as="h1" className={styles.Title}>
        Политика обработки персональных данных
      </Title>
      <div className="row">
        <div className="col-md-6 order-md-2">
          <div className={styles.Image}>
            <img src={image} alt="" />
          </div>
        </div>
        <div className="col-md-6 order-md-1">
          <div className={styles.Info}>
            <Title type="h4" className={styles.Subtitle}>
              1. Общие положения
            </Title>
            <p className={styles.Text}>
              1.1. Настоящая политика обработки персональных данных составлена в соответствии с
              требованиями Федерального закона от 27.07.2006 №152-ФЗ «О персональных данных» и
              определяет порядок обработки персональных данных и меры по обеспечению безопасности
              персональных данных ООО «Бизнес-Школа Своего Дела» (далее – Оператор).
            </p>
            <p className={styles.Text}>
              1.2. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности
              соблюдение прав и свобод человека и гражданина при обработке его персональных данных,
              в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
            </p>
            <p className={styles.Text}>
              1.3. Настоящая политика Оператора в отношении обработки персональных данных (далее –
              Политика) применяется ко всей информации, которую Оператор может получить о
              посетителях веб-сайта https://bizhouse.ru.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)
