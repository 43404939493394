import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image1 from "./assets/image1.svg"
import image2 from "./assets/image2.svg"
import styles from "./index.module.css"

export const Care = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={cn("row", styles.Row)}>
        <div className="col-md-5 order-md-2">
          <div className={styles.MainText}>
            <Title type="h3">Вам закручивают гайки? Мы раскрутим обратно.</Title>
            <p className={styles.Text}>
              Нам важно, чтобы ваш бизнес развивался. А&nbsp;чтобы он&nbsp;развивался быстрее,
              мы&nbsp;подскажем, как не&nbsp;привлечь внимание госорганов и&nbsp;избежать блокировки
              счета по&nbsp;115-ФЗ, а&nbsp;если счет все-таки заблокировали&nbsp;&mdash; поможем
              разблокировать.
            </p>
          </div>
        </div>
        <div className="col-md-7 order-md-1">
          <div className={styles.ImageOne}>
            <img src={image1} alt="" />
          </div>
        </div>
      </div>
      <div className={cn("row")}>
        <div className="col-md-6">
          <div className={styles.SubText}>
            <Title type="h3">Любые перемены, всегда к лучшему.</Title>
            <p className={styles.Text}>
              Неважно хотите вы&nbsp;открыть новую компанию или ликвидировать старую, а&nbsp;может
              и&nbsp;то, и другое сразу. Мы&nbsp;разложим по&nbsp;полочкам весь сценарий, выберем
              наиболее подходящую систему налогообложения или метод ликвидации вашей организации,
              сами подадим и&nbsp;заберем все документы в&nbsp;органы, вам даже не&nbsp;нужно
              оплачивать госпошлину и&nbsp;ходить к&nbsp;нотариусу.
            </p>
            <p className={styles.Text}>
              Помимо этого, внесем любые изменения в&nbsp;вашу действующую компанию, будь&nbsp;то
              смена директора, изменение ОКВЭД или&nbsp;юр. адреса.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className={styles.ImageTwo}>
            <img src={image2} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
