import React from "react"

import { Title } from "~/components/Title/Title"

import styles from "./index.module.css"

export const Terms = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className="row">
        <div className="col-md-12">
          <Title type="h4" className={styles.Subtitle}>
            2. Основные понятия, используемые в Политике
          </Title>
          <p className={styles.Text}>
            2.1. Автоматизированная обработка персональных данных – обработка персональных данных с
            помощью средств вычислительной техники;
          </p>
          <p className={styles.Text}>
            2.2. Блокирование персональных данных – временное прекращение обработки персональных
            данных (за исключением случаев, если обработка необходима для уточнения персональных
            данных);
          </p>
          <p className={styles.Text}>
            2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ
            для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу
            https://bizhouse.ru;
          </p>
          <p className={styles.Text}>
            2.4. Информационная система персональных данных — совокупность содержащихся в базах
            данных персональных данных, и обеспечивающих их обработку информационных технологий, и
            технических средств;
          </p>
          <p className={styles.Text}>
            2.5. Обезличивание персональных данных — действия, в результате которых невозможно
            определить без использования дополнительной информации принадлежность персональных
            данных конкретному Пользователю или иному субъекту персональных данных;
          </p>
          <p className={styles.Text}>
            2.6. Обработка персональных данных – любое действие (операция) или совокупность действий
            (операций), совершаемых с использованием средств автоматизации или без использования
            таких средств с персональными данными, включая сбор, запись, систематизацию, накопление,
            хранение, уточнение (обновление, изменение), извлечение, использование, передачу
            (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
            уничтожение персональных данных;
          </p>
          <p className={styles.Text}>
            2.7. Оператор – государственный орган, муниципальный орган, юридическое или физическое
            лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие
            обработку персональных данных, а также определяющие цели обработки персональных данных,
            состав персональных данных, подлежащих обработке, действия (операции), совершаемые с
            персональными данными;
          </p>
          <p className={styles.Text}>
            2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к
            определенному или определяемому Пользователю веб-сайта https://bizhouse.ru;
          </p>
          <p className={styles.Text}>
            2.9. Пользователь – любой посетитель веб-сайта https://bizhouse.ru;
          </p>
          <p className={styles.Text}>
            2.10. Предоставление персональных данных – действия, направленные на раскрытие
            персональных данных определенному лицу или определенному кругу лиц;
          </p>
          <p className={styles.Text}>
            2.11. Распространение персональных данных – любые действия, направленные на раскрытие
            персональных данных неопределенному кругу лиц (передача персональных данных) или на
            ознакомление с персональными данными неограниченного круга лиц, в том числе
            обнародование персональных данных в средствах массовой информации, размещение в
            информационно-телекоммуникационных сетях или предоставление доступа к персональным
            данным каким-либо иным способом;
          </p>
          <p className={styles.Text}>
            2.12. Трансграничная передача персональных данных – передача персональных данных на
            территорию иностранного государства органу власти иностранного государства, иностранному
            физическому или иностранному юридическому лицу;
          </p>
          <p className={styles.Text}>
            2.13. Уничтожение персональных данных – любые действия, в результате которых
            персональные данные уничтожаются безвозвратно с невозможностью дальнейшего
            восстановления содержания персональных данных в информационной системе персональных
            данных и (или) уничтожаются материальные носители персональных данных.
          </p>
        </div>
      </div>
    </div>
  </div>
)
