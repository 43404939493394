import React from "react"
import { Helmet } from "react-helmet"

import { Feedback } from "~/components/Feedback/Feedback"
import { More } from "~/components/More/More"

import { Hero } from "./components/Hero/Hero"
import { Price } from "./components/Price/Price"

export const EmploymentContract = () => (
  <>
    <Helmet>
      <title>Составление трудовых договоров</title>
      <meta
        name="description"
        content="Грамотно составленый трудовой договоров позволяет контролировать деятельность сотрудников, обеспечить стабильность и защитить ваши интересы."
      />
    </Helmet>
    <Hero />
    <Price />
    <More />
    <Feedback />
  </>
)
