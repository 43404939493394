import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Price = () => (
  <div className={styles.Wrapper}>
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className={styles.Info}>
            <Title type="h3" className={styles.Title}>
              Как это работает?
            </Title>
            <div className={cn(styles.Item, styles.Term)}>
              <p className={styles.Text}>
                Перед консультацией мы созваниваемся, Вы описываете свой вопрос, мы его обсуждаем
                и&nbsp;уточняем детали. После телефонного разговора Вы принимаете решение
                о&nbsp;дальнейшем взаимодействии.
              </p>
              <p className={styles.Text}>
                Далее мы согласовываем дату и&nbsp;время бизнес консультации. Затем, мы высылаем Вам
                предварительные вопросы, касающиеся Вашего запроса, на&nbsp;которые необходимо
                ответить и&nbsp;прислать ответы до нашей встречи (если возникает такая
                необходимость).
              </p>
              <p className={styles.Text}>
                При таком подходе, наша консультация пройдет максимально эффективно.
              </p>
            </div>
            <div className={cn(styles.Item, styles.Price)}>
              <p className={styles.Text}>
                На консультации, мы исследуем вопрос с&nbsp;разных сторон. У вас будет возможность
                посмотреть на&nbsp;него под другим углом и&nbsp;увидеть несколько вариантов его
                решения.
              </p>
              <p className={styles.Text}>
                После стандартной бизнес консультации мы оказываем Вам поддержку в&nbsp;течении
                одной недели.
              </p>
              <p className={cn(styles.Text, styles.PriceInfo)}>
                Стоимость консультации&nbsp;– 1000&nbsp;рублей/час.
              </p>
            </div>
          </div>
        </div>
        <div className={cn("col-md-6", styles.ImageWrapper)}>
          <div className={styles.Image}>
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
