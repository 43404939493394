import React from "react"
import { Helmet } from "react-helmet"

import { Feedback } from "~/components/Feedback/Feedback"
import { More } from "~/components/More/More"

import { Hero } from "./components/Hero/Hero"
import { Price } from "./components/Price/Price"

export const Consultation = () => (
  <>
    <Helmet>
      <title>Бизнес консультации</title>
      <meta
        name="description"
        content="Индивидуальные бизнес-консультации по исследованию, разработке и реализации бизнес задач, стоящих перед предпринимателем."
      />
    </Helmet>
    <Hero />
    <Price />
    <More />
    <Feedback />
  </>
)
