import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={styles.Service}>
        <div className={cn("row", styles.Row)}>
          <div className="col-md-6">
            <div className={styles.Info}>
              <Title type="h2" as="h1" className={styles.Title}>
                Представительство в трудовой инспекции
              </Title>
              <p className={styles.Text}>
                Если у вас возник спор с вашим работником и вы никак не придете к&nbsp;компромиссу,
                то рано или поздно в дело вмешается третья сторона: комиссия по трудовым спорам,
                трудовая инспекция, прокуратура или суд. Наши специалисты проведут анализ, оценят
                варианты, предложат наиболее эффективную стратегию взаимодействия с проверяющими
                органами и представят ваши интересы в трудовой инспекции.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.Image}>
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
