import React from "react"
import { Helmet } from "react-helmet"

import { Feedback } from "~/components/Feedback/Feedback"
import { More } from "~/components/More/More"

import { Hero } from "./components/Hero/Hero"
import { Price } from "./components/Price/Price"

export const WorkInspection = () => (
  <>
    <Helmet>
      <title>Представительство в трудовой инспекции</title>
      <meta
        name="description"
        content="Наши специалисты разрабатывают эффективные стратегии взаимодействия с проверяющими органами и представляют ваши интересы в трудовой инспекции."
      />
    </Helmet>
    <Hero />
    <Price />
    <More />
    <Feedback />
  </>
)
