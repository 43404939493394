import React from "react"
import { Helmet } from "react-helmet"

import { Hero } from "./components/Hero/Hero"
import { Map } from "./components/Map/Map"
import { Mask } from "./components/Mask/Mask"

export const Contacts = () => (
  <>
    <Helmet>
      <title>Контакты</title>
      <meta
        name="description"
        content="Вы можете связаться с нами любым, удобным для вас, способом."
      />
    </Helmet>

    <Hero />
    <Mask>
      <Map />
    </Mask>
  </>
)
