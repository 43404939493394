import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={styles.Service}>
        <div className={cn("row", styles.Row)}>
          <div className="col-md-6">
            <div className={styles.Info}>
              <Title type="h2" as="h1" className={styles.Title}>
                Составление договоров о материальной ответственности
              </Title>
              <p className={styles.Text}>
                Многие виды трудовой деятельности допускают возможность нанесения ущерба
                работодателю. В такой ситуации он вправе запросить компенсацию. Базовым основанием
                для наложения санкции будет договор о полной коллективной и индивидуальной
                материальной ответственности.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.Image}>
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
