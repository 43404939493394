import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={cn("row", styles.Row)}>
        <div className="col-md-6">
          <div className={styles.Info}>
            <Title type="h2" as="h1" className={styles.Title}>
              {"<span>Комплексное бухгалтерское</span> обслуживание вашей компании"}
            </Title>
            <p className={styles.Text}>
              Прозрачный и автоматизированный <br />
              учет с гарантией оптимизации.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className={styles.Image}>
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
