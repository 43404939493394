import React from "react"

import { Title } from "~/components/Title/Title"

import styles from "./index.module.css"

export const Target = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <Title type="h3">Для кого этот курс?</Title>
      <div className="row">
        <div className="col-md-6">
          <p className={styles.Text}>
            Курс предназначен только для индивидуальных предпринимателей, руководителей и владельцев
            малого и среднего бизнеса, а также для тех, кто собирается ими стать. Если у вас есть
            намерение и четкий бизнес-план, приходите.
          </p>
          <p className={styles.Text}>
            Если у вас просто много свободного времени и вы думаете: «почему бы мне не заняться
            бизнесом, все же занимаются» – этот курс не для вас, так как я, все равно, вам ничем не
            помогу.
          </p>
        </div>
        <div className="col-md-6">
          <p className={styles.Text}>
            Предупреждаю заранее, я не стану отвечать на вопросы типа: «Мне кажется, что мне
            неправильно насчитали пособие по уходу за ребенком. Что делать?». Для этого есть
            индивидуальные консультации.
          </p>
          <p className={styles.Text}>
            Я не стану отвечать на вопросы типа: «Как мне получить налоговый вычет на купленную
            недвижимость». Я не ваш бухгалтер, разбирайтесь сами. На этом курсе мы будем обсуждать
            только проблемы бизнеса и пути их решения, ничего больше.
          </p>
        </div>
      </div>
    </div>
  </div>
)
