import cn from "classnames"
import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

import { Title } from "~/components/Title/Title"
import { shuffle } from "~/hooks/shuffle"

import arrow from "./assets/arrow.svg"
import styles from "./index.module.css"

export const More = ({ className }) => {
  const list = [
    { title: "Бухгалтерское обслуживание", link: "/buhgalterskoe-obsluzhivanie" },
    {
      title: "Бизнес консультации",
      link: "/biznes-konsultacii",
    },
    { title: "Регистрация ООО, АО, ЧОО", link: "/registraciya-ooo" },

    { title: "Регистрация ИП", link: "/registraciya-ip" },

    { title: "Ликвидация ООО", link: "/likvidaciya-ooo" },

    { title: "Ликвидация ИП", link: "/likvidaciya-ip" },

    { title: "Добавление ОКВЭД в&nbsp;гос. реестр", link: "" },

    { title: "Смена директора ООО", link: "" },

    { title: "Смена учредителя ООО", link: "" },

    { title: "Смена названия ООО", link: "" },

    { title: "Составление претензий", link: "/sostavlenie-pretenzij" },
    {
      title: "Составление жалоб и&nbsp;заявлений",
      link: "/zhaloby-i-zayavleniya",
    },
    { title: "Составление и&nbsp;анализ договоров", link: "/sostavlenie-dogovorov" },
    { title: "Представительство в&nbsp;Арбитражном суде", link: "/predstavitelstvo-v-sude" },
    { title: "Составление штатного расписания ", link: "/shtatnoe-raspisanie" },
    { title: "Составление трудовых договоров ", link: "/trudovoj-dogovor" },
    { title: "Представительство в трудовой инспекции", link: "/trudovaya-inspekciya" },
    { title: "Представительство в прокуратуре", link: "/prokuratura" },
  ]

  const location = useLocation()

  const [randomItems, setRandomItems] = useState([])

  useEffect(() => {
    const filteredList = list.filter(({ link }) => link && location.pathname !== link)
    const shuffledList = shuffle(filteredList)
    setRandomItems([shuffledList[0], shuffledList[1], shuffledList[2]])
  }, [location])

  return (
    <div className={styles.Container}>
      <div className={cn(styles.Wrapper, className)}>
        <Title type="h3" className={styles.Title}>
          Вам может быть интересно
        </Title>
        <div className={styles.Row}>
          {randomItems.map(({ title, link }, i) => (
            <div className={styles.ItemWrapper} key={i}>
              <div className={styles.Item}>
                <Link to={link} className={styles.Link} />
                <div className={styles.ItemInner}>
                  <span className={styles.ItemTitle} dangerouslySetInnerHTML={{ __html: title }} />
                </div>
                <div className={styles.Arrow}>
                  <img src={arrow} className={styles.ArrowIcon} alt="" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
