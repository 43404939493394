import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"

import { services } from "~/data/services"

import { SlidingItem } from "./components/SlidingItem/SlidingItem"
import styles from "./index.module.css"
export const Menu = ({ location }) => {
  const [activeItem, changeActiveItem] = useState(0)

  useEffect(() => {
    services.map((service, i) => {
      service.list.map(({ link }) => {
        if (link === location.pathname) {
          changeActiveItem(i)
        }
      })
    })
  }, [])

  return (
    <div className={styles.Menu}>
      <div className={styles.Services}>
        <span className={styles.Title}>Услуги</span>
        {services.map(({ name, list }, i) => (
          <SlidingItem
            list={list}
            name={name}
            isActive={activeItem === i}
            key={i}
            changeActiveItem={() =>
              activeItem === i ? changeActiveItem(null) : changeActiveItem(i)
            }
          />
        ))}
      </div>
      <div className={styles.Info}>
        <NavLink to="/course" className={styles.Link} activeClassName={styles.LinkActive}>
          Обучение
        </NavLink>

        <NavLink to="/about" className={styles.Link} activeClassName={styles.LinkActive}>
          О компании
        </NavLink>

        <NavLink to="/contacts" className={styles.Link} activeClassName={styles.LinkActive}>
          Контакты
        </NavLink>
      </div>
      <NavLink to="/policy" className={styles.Policy} activeClassName={styles.LinkActive}>
        Политика конфиденциальности
      </NavLink>
      <div className={styles.Social}>
        <p className={styles.SocialTitle}>Мы в соц. сетях:</p>
        <a href="https://www.instagram.com/biz.house/" className={styles.SocialItem}>
          <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.999 4.377a4.624 4.624 0 100 9.248 4.624 4.624 0 000-9.248zm0 7.627a3.004 3.004 0 110-6.008 3.004 3.004 0 010 6.008zM13.806 5.285a1.078 1.078 0 100-2.156 1.078 1.078 0 000 2.156z" />
            <path d="M17.533 3.11A4.605 4.605 0 0014.9.48a6.607 6.607 0 00-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 00-2.184.42A4.6 4.6 0 00.477 3.11a6.585 6.585 0 00-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 002.634 2.632 6.583 6.583 0 002.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.616 6.616 0 002.186-.42 4.613 4.613 0 002.633-2.632c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.583 6.583 0 00-.421-2.217zm-1.218 9.533a5.046 5.046 0 01-.311 1.688 2.987 2.987 0 01-1.712 1.71c-.535.2-1.1.305-1.67.312-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.961 4.961 0 01-1.669-.311 2.984 2.984 0 01-1.72-1.711 5.08 5.08 0 01-.31-1.67c-.043-.95-.053-1.217-.053-3.653 0-2.437 0-2.686.053-3.655a5.038 5.038 0 01.31-1.687c.306-.79.931-1.41 1.72-1.712a5.01 5.01 0 011.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 011.67.31 2.99 2.99 0 011.712 1.713c.197.535.302 1.099.311 1.669.043.95.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011v-.001z" />
          </svg>
        </a>
      </div>
    </div>
  )
}
