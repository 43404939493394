import cn from "classnames"
import React, { useState } from "react"
import Select, { components } from "react-select"

import { Title } from "~/components/Title/Title"
import { tariff } from "~/data/tariff"

import styles from "./index.module.css"

const DropdownIndicator = (props) => {
  return (
    components.IndicatorsContainer && (
      <components.IndicatorsContainer {...props}>
        <div className={styles.ArrowContainer}>
          <svg
            className={styles.Arrow}
            width="12"
            height="7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6.342 6.413l5.466-5.312a.695.695 0 00.068-.877c-.19-.264-.525-.3-.752-.08L6 5.124.876.144C.65-.076.313-.04.124.224a.695.695 0 00.068.877l2.733 2.656 2.732 2.656A.49.49 0 006 6.558a.488.488 0 00.342-.145z" />
          </svg>
        </div>
      </components.IndicatorsContainer>
    )
  )
}

export const Tariff = () => {
  const options = [
    { value: 0, label: tariff[0].name },
    { value: 1, label: tariff[1].name },
    { value: 2, label: tariff[2].name },
  ]

  const [activeType, changeActiveType] = useState(0)
  const [isAnimating, changeAnimationStatus] = useState(false)

  const setActiveType = (type) => {
    if (type.value !== activeType) {
      changeActiveType(type.value)
      changeAnimationStatus(true)
      setTimeout(() => {
        changeAnimationStatus(false)
      }, 500)
    }
  }

  const customStyles = {
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "10px 10px 8px",
      opacity: "0.4",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "300",
    }),
    control: (provided, state) => ({
      ...provided,
      outline: "none",
      border: "none",
      boxShadow: "0 0 4px rgba(0,0,0, .2)",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#425dbb" : "",
    }),
  }

  return (
    <div className={styles.Container}>
      <div className={styles.Wrapper}>
        <div className={styles.Head}>
          <Title type="h3">Тарифы</Title>

          <div className={styles.SelectWrapper}>
            <Select
              className={styles.Select}
              value={[{ value: activeType, label: tariff[activeType].name }]}
              onChange={setActiveType}
              options={options}
              isSearchable={false}
              components={{ DropdownIndicator }}
              styles={customStyles}
            />
          </div>
        </div>

        <div className={styles.TableWrapper}>
          <div className={styles.TablePadding}>
            <div className={styles.Table}>
              <div className={styles.Row}>
                <div className={cn(styles.Col, styles.ColFirst)}>
                  <div className={styles.Name} />
                </div>
                <div className={cn(styles.Col, styles.ColSmall, styles.ColTitle)}>
                  <p> Малый бизнес</p>
                  <span
                    className={cn(styles.Animated, { [styles.IsAnimating]: isAnimating })}
                    dangerouslySetInnerHTML={{ __html: tariff[activeType].prices[0] }}
                  />
                </div>
                <div className={cn(styles.Col, styles.ColSmall, styles.ColTitle)}>
                  <p>Набираем обороты</p>
                  <span
                    className={cn(styles.Animated, { [styles.IsAnimating]: isAnimating })}
                    dangerouslySetInnerHTML={{ __html: tariff[activeType].prices[1] }}
                  />
                </div>
                <div className={cn(styles.Col, styles.ColSmall, styles.ColTitle)}>
                  <p>Корпоративный</p>
                  <span
                    className={cn(styles.Animated, { [styles.IsAnimating]: isAnimating })}
                    dangerouslySetInnerHTML={{ __html: tariff[activeType].prices[2] }}
                  />
                </div>
              </div>
              <div className={styles.Row}>
                <div className={cn(styles.Col, styles.ColFirst)}>
                  <div className={styles.Name}>Месячный оборот компании</div>
                </div>
                <div className={cn(styles.Col, styles.ColSmall)}>
                  <div
                    className={cn(styles.Animated, {
                      [styles.IsAnimating]: isAnimating,
                    })}
                  >
                    {tariff[activeType].turnover[0]}
                  </div>
                </div>
                <div className={cn(styles.Col, styles.ColSmall)}>
                  <div
                    className={cn(styles.Animated, {
                      [styles.IsAnimating]: isAnimating,
                    })}
                    style={{ transitionDelay: "100ms" }}
                  >
                    {tariff[activeType].turnover[1]}
                  </div>
                </div>
                <div className={cn(styles.Col, styles.ColSmall)}>
                  <div
                    className={cn(styles.Animated, {
                      [styles.IsAnimating]: isAnimating,
                    })}
                    style={{ transitionDelay: "200ms" }}
                  >
                    {tariff[activeType].turnover[2]}
                  </div>
                </div>
              </div>
              <div className={styles.Row}>
                <div className={cn(styles.Col, styles.ColFirst)}>
                  <div className={styles.Name}>
                    <p>Расчет зарплаты и&nbsp;налогов сотрудников</p>
                    <span>Каждый следующий 500&nbsp;₽</span>
                  </div>
                </div>
                <div className={cn(styles.Col, styles.ColSmall)}>
                  <div
                    className={cn(styles.Animated, {
                      [styles.IsAnimating]: isAnimating,
                    })}
                    style={{ transitionDelay: "200ms" }}
                  >
                    {tariff[activeType].salary[0]}
                  </div>
                </div>
                <div className={cn(styles.Col, styles.ColSmall)}>
                  <div
                    className={cn(styles.Animated, {
                      [styles.IsAnimating]: isAnimating,
                    })}
                    style={{ transitionDelay: "200ms" }}
                  >
                    {tariff[activeType].salary[1]}
                  </div>
                </div>
                <div className={cn(styles.Col, styles.ColSmall)}>
                  <div
                    className={cn(styles.Animated, {
                      [styles.IsAnimating]: isAnimating,
                    })}
                    style={{ transitionDelay: "200ms" }}
                  >
                    {tariff[activeType].salary[2]}
                  </div>
                </div>
              </div>
              <div className={styles.Row}>
                <div className={cn(styles.Col, styles.ColFirst)}>
                  <div className={styles.Name}>
                    Устные консультации юриста по вопросам ведения бизнеса
                  </div>
                </div>
                <div className={cn(styles.Col, styles.ColSmall, styles.ColTitle)}>5 в месяц</div>
                <div className={cn(styles.Col, styles.ColSmall, styles.ColTitle)}>10 в месяц</div>
                <div className={cn(styles.Col, styles.ColSmall, styles.ColTitle)}>
                  Неограниченно
                </div>
              </div>
              <div className={styles.Row}>
                <div className={cn(styles.Col, styles.ColFirst)}>
                  <div className={styles.Name}>Внешнеэкономическая деятельность</div>
                </div>
                <div className={cn(styles.Col, styles.ColСommon, styles.ColTitle)}>
                  +20% от стоимости тарифа
                </div>
              </div>
              <div className={styles.Row}>
                <div className={cn(styles.Col, styles.ColFirst)}>
                  <div className={styles.Name}>Возмещение НДС</div>
                </div>
                <div className={cn(styles.Col, styles.ColСommon, styles.ColTitle)}>от 20 000 ₽</div>
              </div>
              <div className={styles.Row}>
                <div className={cn(styles.Col, styles.ColFirst)}>
                  <div className={styles.Name}>Возмещение ФСС</div>
                </div>
                <div className={cn(styles.Col, styles.ColСommon, styles.ColTitle)}>от 10 000 ₽</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
