import cn from "classnames"
import React, { useRef } from "react"

import styles from "./index.module.css"

export const SlidingItem = ({ question, answer, isActive, changeActiveItem }) => {
  const ref = useRef()
  return (
    <div className={cn(styles.Item, { [styles.IsActive]: isActive })} onClick={changeActiveItem}>
      <div className={styles.Question}>
        {question}
        <span className={styles.Arrow}>
          <svg width="5" height="10" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.613 5.01L1.059.81A.42.42 0 00.472.76a.406.406 0 00-.053.578L3.75 5.272.419 9.207a.406.406 0 00.053.578.42.42 0 00.587-.053l3.554-4.197a.406.406 0 000-.526z" />
          </svg>
        </span>
      </div>
      <div
        className={styles.AnswerWrapper}
        ref={ref}
        style={{ maxHeight: (isActive && ref.current && ref.current.scrollHeight) || "" }}
      >
        <div className={styles.Answer} dangerouslySetInnerHTML={{ __html: answer }} />
      </div>
    </div>
  )
}
