import cn from "classnames"
import React from "react"
import { Link } from "react-router-dom"

import { Title } from "~/components/Title/Title"

import image1 from "./assets/image1.svg"
import image2 from "./assets/image2.svg"
import image3 from "./assets/image3.svg"
import image4 from "./assets/image4.svg"
import styles from "./index.module.css"

export const Advantages = () => {
  const items = [
    {
      image: image1,
      text: "Держим в&nbsp;курсе всех изменений и&nbsp;нововведений в&nbsp;законодательстве.",
    },
    {
      image: image2,
      text: "Предоставим и сами установим ПО для документо- оборота.",
    },
    {
      image: image3,
      text: "Понятно и вовремя отвечаем на возникшие вопросы.",
    },
    {
      image: image4,
      text: "И даже выручим, когда все плохо.",
    },
  ]

  return (
    <div className={styles.Container}>
      <div className={styles.Wrapper}>
        <div className={cn("row", styles.Row)}>
          <div className="col-md-5">
            <div className={styles.Info}>
              <Title type="h3">Бухгалтерия, с&nbsp;которой вы&nbsp;чувствуете себя уверенно.</Title>
              <p className={styles.Text}>
                Рассчитать все налоги и&nbsp;платежи, подготовить и&nbsp;сдать
                отчетность&nbsp;&mdash; это лишь вершина айсберга. Мы&nbsp;анализируем ваши
                финансовые операции и&nbsp;налоговые риски для оптимизации налогооблагаемой базы,
                с&nbsp;нами вы&nbsp;не&nbsp;заплатите лишнего.
              </p>
              <div href={""} className={styles.Link}>
                <Link to="/buhgalterskoe-obsluzhivanie" className={styles.Button}>
                  Подробнее
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.List}>
              {items.map(({ image, text }, i) => (
                <div key={i} className={styles.Item}>
                  <div className={styles.ItemInner}>
                    <img src={image} alt="" className={styles.Image} />
                    <p className={styles.ItemText} dangerouslySetInnerHTML={{ __html: text }} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
