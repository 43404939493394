import cn from "classnames"
import { Field, Form, Formik } from "formik"
import React from "react"
import MaskedInput from "react-text-mask"

import { Title } from "~/components/Title/Title"
import { useSetState } from "~/hooks/useSetState"

import image from "./assets/image.png"
import { ErrorPopup } from "./components/ErrorPopup/ErrorPopup"
import { SuccessPopup } from "./components/SuccessPopup/SuccessPopup"
import styles from "./index.module.css"

export const Feedback = () => {
  const [popupState, changePopupState] = useSetState({
    success: false,
    error: false,
  })

  const showPopup = (success) => {
    changePopupState({ success: success, error: !success })
  }

  const closePopup = () => {
    changePopupState({ success: false, error: false })
  }

  const submitHandler = (values, { setSubmitting, resetForm }) => {
    fetch("/api/mail", {
      method: "POST",
      body: JSON.stringify(values, null, 2),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text()
        }
        throw new Error("Something went wrong.")
      })
      .then(function(text) {
        showPopup(true)
        resetForm()
        setSubmitting(false)
      })
      .catch((e) => {
        showPopup(false)
        setSubmitting(false)
      })
  }

  const phoneMask = [
    // eslint-disable-next-line prettier/prettier
    "+", "7", " ", "(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/,
  ]

  const validateName = (value) => {
    if (!value) {
      return 'Поле "Имя" обязательно для заполнения'
    } else if (!/^[а-яА-Я\s]*$/i.test(value)) {
      return 'Поле "Ваше имя" может содержать только кириллицу'
    }
  }

  const validatePhone = (value) => {
    if (!value) {
      return 'Поле "Номер телефона" обязательно для заполнения'
    } else if (!/^\D*(\d\D*){11}$/i.test(value)) {
      return "Неправильный номер телефона"
    }
  }

  return (
    <>
      {popupState.success && <SuccessPopup closeHandler={closePopup} />}
      {popupState.error && <ErrorPopup closeHandler={closePopup} />}
      <div className={styles.Container}>
        <div className={styles.Wrapper}>
          <div className={styles.Inner}>
            <Title className={styles.Title} type="h2">
              {"Давайте <br />знакомиться"}
            </Title>
            <Formik
              onSubmit={submitHandler}
              initialValues={{
                name: "",
                phone: "",
              }}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form autoComplete="off">
                  <div className="row">
                    <div className={cn("col-md-8", styles.Controls)}>
                      <div className={cn(styles.Name, styles.InputWrapper)}>
                        <Field
                          name="name"
                          placeholder="Ваше имя"
                          className={styles.Input}
                          validate={validateName}
                          maxLength="30"
                        />
                        {errors.name && touched.name && (
                          <div className={styles.Error}>{errors.name}</div>
                        )}
                      </div>
                      <div className={cn(styles.Phone, styles.InputWrapper)}>
                        <img src={image} alt="" className={styles.Image} />

                        <Field
                          validate={validatePhone}
                          name="phone"
                          render={({ field }) => {
                            return (
                              <MaskedInput
                                className={styles.Input}
                                mask={phoneMask}
                                {...field}
                                placeholder="Ваш телефон"
                              />
                            )
                          }}
                        />
                        {errors.phone && touched.phone && (
                          <div className={styles.Error}>{errors.phone}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <div className={styles.ButtonWrapper}>
                        <button
                          type="submit"
                          disabled={isSubmitting || errors.phone || errors.name}
                          className={styles.Button}
                        >
                          Получить консультацию
                        </button>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className={styles.Note}>
                        Оставляя заявку, вы&nbsp;соглашаетесь на&nbsp;обработку персональных данных.
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}
