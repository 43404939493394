import cn from "classnames"
import React, { useState } from "react"

import { Title } from "~/components/Title/Title"

import { SlidingItem } from "./components/SlidingItem/SlidingItem"
import styles from "./index.module.css"

export const Faq = () => {
  const items = [
    {
      question:
        "Что если месячные обороты по банку и кассе превысили допустимые лимиты по выбранному тарифу?",
      answer:
        "В данном случае, каждая ситуация рассматривается нами индивидуально. Если обороты выросли из-за одной проведенной крупной сделки, стоимость обслуживания не пересматривается. Если обороты выросли из-за множества мелких сделок, пересматривается стоимость обслуживания из расчета количества первичной документации.",
    },
    {
      question:
        "Можно ли уменьшить стоимость бухгалтерского обслуживания, если вести только налоговый учет для ООО?",
      answer:
        "Нет, обязанность вести бухгалтерский учет закреплена Федеральным законом от 06.12.2011 №402-ФЗ (ред. от 26.07.2019). По договору на предоставление бухгалтерских услуг мы принимаем на себя ответственность за ведение как налогового, так и бухгалтерского учета.",
    },
    {
      question: "Какую ответственность несет ваша компания при некорректном ведении учета?",
      answer:
        "По условиям договора на оказание бухгалтерских услуг, мы принимаем на себя материальную ответственность за ведение учета. В случае, если при проверке ИФНС начислит вам штраф или пени за некорректность учета или расчета налога, мы компенсируем вам данный убыток в 100 % размере.",
    },
    {
      question: "Входит ли сдача ликвидационного баланса в стоимость бухгалтерского обслуживания?",
      answer: "Нет, ликвидация ООО это отдельная юридическая услуга и оплачивается она отдельно.",
    },
  ]

  const [activeItem, changeActiveItem] = useState(null)

  return (
    <div className="container">
      <div className={styles.Wrapper}>
        <Title type="h3">Частые вопросы</Title>
        <div className={cn("row", styles.Row)}>
          <div className={cn("col-md-6", styles.Col)}>
            <div className={styles.List}>
              {items.map(({ question, answer }, i) => (
                <React.Fragment key={i}>
                  {i < 2 && (
                    <SlidingItem
                      question={question}
                      answer={answer}
                      isActive={activeItem === i}
                      changeActiveItem={() =>
                        activeItem === i ? changeActiveItem(null) : changeActiveItem(i)
                      }
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className={cn("col-md-6", styles.Col)}>
            <div className={styles.List}>
              {items.map(({ question, answer }, i) => (
                <React.Fragment key={i}>
                  {i > 1 && (
                    <SlidingItem
                      question={question}
                      answer={answer}
                      isActive={activeItem === i + 2}
                      changeActiveItem={() =>
                        activeItem === i + 2 ? changeActiveItem(null) : changeActiveItem(i + 2)
                      }
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
