import cn from "classnames"
import React from "react"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Price = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={styles.Inner}>
        <div className={cn("row", styles.Row)}>
          <div className="col-sm-6 col-md-5 order-sm-2">
            <p className={styles.Price}>
              Стоимость услуги&nbsp;&mdash; <br />
              от 1 500&nbsp;рублей.
            </p>
          </div>
          <div className="col-sm-6 col-md-7 order-sm-1">
            <div className={styles.Image}>
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
