import cn from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"
import { CSSTransition } from "react-transition-group"

import { getScrollbarWidth } from "~/hooks/getScrollbarWidth"

import burger from "./assets/burger.svg"
import cross from "./assets/cross.svg"
import logo from "./assets/logo.svg"
import { Menu } from "./components/Menu/Menu"
import styles from "./index.module.css"

export const Header = () => {
  const [isOpen, toggleMenu] = useState(false)
  const [headerPadding, setHeaderPadding] = useState(0)
  const location = useLocation()
  const menuRef = useRef(null)
  const contentRef = useRef(null)
  const innerRef = useRef(null)

  const keyPress = (e) => {
    if (e.keyCode === 27) {
      toggleMenu(false)
    }
  }

  const clickHandler = (e) => {
    if (contentRef.current && !contentRef.current.contains(e.target)) {
      toggleMenu(false)
      document.removeEventListener("click", clickHandler)
    }
  }

  const toggleMenuHandler = () => {
    if (!isOpen) {
      document.addEventListener("click", clickHandler)
    }
    toggleMenu(!isOpen)
  }

  useEffect(() => {
    document.addEventListener("keydown", keyPress)
    toggleMenu(false)
    return () => document.removeEventListener("keydown", keyPress)
  }, [location])

  useEffect(() => {
    const scrollbarWidth = getScrollbarWidth() + "px"

    if (isOpen && innerRef.current) {
      setHeaderPadding(innerRef.current.scrollHeight)
    } else {
      setHeaderPadding(0)
    }

    if (isOpen) {
      document.body.style.paddingRight = scrollbarWidth
      document.body.classList.add("fixed")
    } else {
      document.body.style.paddingRight = 0
      document.body.classList.remove("fixed")
    }

    if (window.innerWidth > 768) {
      if (isOpen && menuRef && menuRef.current) {
        menuRef.current.style.paddingRight = scrollbarWidth
        innerRef.current.style.paddingRight = scrollbarWidth
      } else if (!isOpen && menuRef && menuRef.current) {
        menuRef.current.style.paddingRight = 0
        innerRef.current.style.paddingRight = 0
      }
    }
  }, [isOpen])

  return (
    <header className={styles.Header} style={{ paddingTop: headerPadding + "px" }}>
      <div className={cn(styles.Overlay, { [styles.OverlayVisible]: isOpen })} />

      <div className={cn(styles.Wrapper, { [styles.WrapperFixed]: isOpen })}>
        <div className={cn(styles.Content, { [styles.BgWhite]: isOpen })} ref={contentRef}>
          <div ref={innerRef}>
            <div className="container">
              <div className={styles.Inner}>
                <div
                  className={cn(styles.Burger, { [styles.IsOpen]: isOpen })}
                  onClick={toggleMenuHandler}
                >
                  <div className={styles.BurgerIconWrapper}>
                    <img src={burger} alt="" className={styles.BurgerIcon} />
                    <img src={cross} alt="" className={styles.Cross} />
                  </div>
                  <span className={styles.BurgerText}>Меню</span>
                </div>
                <div className={styles.Logo}>
                  <img src={logo} alt="" />
                  <Link className={styles.LogoLink} to="/" />
                </div>
                <a href="tel:+78002220236" className={styles.Phone}>
                  8 800 222-02-36
                </a>
              </div>
            </div>
          </div>

          <CSSTransition
            in={isOpen}
            unmountOnExit
            timeout={{ exit: 100 }}
            classNames={{
              enter: styles.Enter,
              enterActive: styles.EnterActive,
              exit: styles.Exit,
            }}
          >
            <div className={styles.Menu} ref={menuRef}>
              <div className="container">
                <Menu location={location} />
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
    </header>
  )
}
