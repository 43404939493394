import cn from "classnames"
import React, { useEffect, useRef } from "react"

import { Portal } from "~/components/Portal/Portal"
import { getScrollbarWidth } from "~/hooks/getScrollbarWidth"

import styles from "./index.module.css"

export const Popup = ({ className, closeClassName, children, closeHandler }) => {
  const ref = useRef(null)

  const keyPress = (e) => {
    if (e.keyCode === 27) {
      closeHandler()
    }
  }

  const clickHandler = (e) => {
    if (!ref.current.contains(e.target)) {
      closeHandler()
    }
  }

  useEffect(() => {
    document.body.style.paddingRight = getScrollbarWidth() + "px"
    document.body.style.top = `-${window.pageYOffset}px`
    document.body.classList.add("fixed")
    document.addEventListener("keydown", keyPress)
    return () => {
      const pageYOffset = document.body.style.top
      document.body.classList.remove("fixed")
      document.body.style.top = "auto"
      document.body.style.paddingRight = 0
      window.scrollTo(0, parseInt(pageYOffset || "0", 10) * -1)
      document.removeEventListener("keydown", keyPress)
    }
  }, [])

  return (
    <Portal>
      <div className={styles.Overlay} />
      <div className={cn(styles.Container, className)} onClick={clickHandler}>
        <div className={styles.Wrapper}>
          <div className={styles.Content}>
            <div className={styles.Box}>
              <div className={cn(styles.Close, closeClassName)} onClick={closeHandler}>
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M.935 19.98c.2 0 .398-.076.55-.228L19.78 1.459a.778.778 0 10-1.101-1.1L.385 18.65a.778.778 0 00.55 1.329z" />
                  <path d="M19.228 19.983a.778.778 0 00.55-1.329L1.487.363a.778.778 0 10-1.101 1.1l18.293 18.293c.152.152.35.228.55.228z" />
                </svg>
              </div>
              <div className={styles.Inner} ref={ref}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}
