import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={styles.Service}>
        <div className={cn("row", styles.Row)}>
          <div className="col-md-6">
            <div className={styles.Info}>
              <Title type="h2" as="h1" className={styles.Title}>
                Составление жалоб и заявлений
              </Title>
              <p className={styles.Text}>
                Одной из наиболее распространенной форм обращений в органы власти является жалоба в
                государственный орган. Целью подачи жалобы является восстановление нарушенных прав.
                Нередко заявитель добивается применения мер привлечения к ответственности
                должностного лица государственного органа, не оказавшим содействие или нарушившим
                права.
              </p>
              <p className={styles.Text}>
                Подать жалобу или заявление можно не только в государственные органы, но и в любую
                другую организацию.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.Image}>
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
