import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Documents = () => (
  <div className={styles.Wrapper}>
    <div className="container">
      <div className={cn("row", styles.Row)}>
        <div className="col-md-6">
          <div className={styles.Info}>
            <Title type="h3">Получение готовых документов</Title>
            <p className={styles.Text}>
              Если вы&nbsp;выбрали способ регистрации через онлайн-сервис, то&nbsp;по&nbsp;факту
              регистрации вы&nbsp;получаете пакет документов в&nbsp;электронном виде, заверенный ЭЦП
              налоговой инспекции.
            </p>
            <div className={styles.List}>
              <p className={styles.ListItem}>Лист записи ЕГРИП</p>
              <p className={styles.ListItem}>
                Свидетельство о&nbsp;постановке на&nbsp;учёт в&nbsp;налоговом органе (ИНН). Если
                свидетельство выдавалось раннее, предоставляется уведомление.
              </p>
            </div>
            <p className={styles.Text}>
              Если регистрация происходила через ФНС, то вы получите те же документы, но на бумажном
              носителе.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className={styles.Image}>
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
