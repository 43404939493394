import React from "react"

import { Popup } from "~/components/Popup/Popup"
import { Title } from "~/components/Title/Title"

import styles from "./index.module.css"

export const SuccessPopup = ({ closeHandler }) => {
  return (
    <Popup className={styles.Popup} closeHandler={closeHandler}>
      <div className={styles.Inner}>
        <div className={styles.Info}>
          <Title type="h4" className={styles.PopupTitle}>
            Ваше письмо отправлено!
          </Title>
          <p>В&nbsp;ближайшее время мы&nbsp;свяжемся с&nbsp;вами</p>
        </div>
        <div className={styles.Button} onClick={() => closeHandler()}>
          Ок
        </div>
      </div>
    </Popup>
  )
}
