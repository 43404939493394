import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Partners = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={cn("row", styles.Row)}>
        <div className="col-md-5 order-md-2">
          <div className={styles.MainText}>
            <Title type="h3">Время&nbsp;&mdash; действительно, деньги!</Title>
            <p className={styles.Text}>
              Мы&nbsp;ценим время наших клиентов, поэтому договорились о&nbsp;сотрудничестве
              с&nbsp;крупнейшими банками для того, чтобы вы&nbsp;могли быстро открыть счет
              и&nbsp;начать работать. Мы&nbsp;сами передадим банку-партнеру все ваши данные, после
              чего сотрудник банка подъедет в&nbsp;любое удобное для вас место для завершения
              регистрации расчетного счета, и&nbsp;всё это абсолютно бесплатно!
            </p>
          </div>
        </div>
        <div className="col-md-7 order-md-1">
          <div className={styles.Image}>
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
