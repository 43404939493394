import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Ability = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className="row">
        <div className="col-md-6">
          <div className={styles.Image}>
            <img src={image} alt="" />
          </div>
        </div>
        <div className="col-md-6">
          <div className={styles.Block}>
            <Title type="h4" className={styles.Subtitle}>
              5. Правовые основания обработки персональных данных
            </Title>
            <p className={styles.Text}>
              5.1. Оператор обрабатывает персональные данные Пользователя только в случае их
              заполнения и/или отправки Пользователем самостоятельно через специальные формы,
              расположенные на сайте https://bizhouse.ru. Заполняя соответствующие формы и/или
              отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с
              данной Политикой.
            </p>
            <p className={styles.Text}>
              5.2. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это
              разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и
              использование технологии JavaScript).
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)
