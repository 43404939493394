import React from "react"

import { Title } from "~/components/Title/Title"

import styles from "./index.module.css"

export const Reasons = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <Title type="h3">Зачем этот курс был создан?</Title>
      <div className="row">
        <div className="col-md-6">
          <p className={styles.Text}>
            В&nbsp;нынешних условиях динамично меняющегося законодательства и&nbsp;ужесточения
            системы налогового и&nbsp;банковского контроля, бизнесу все сложнее работать
            на&nbsp;рынке.
          </p>
          <p className={styles.Text}>
            Вместо того, чтобы заниматься развитием и&nbsp;расширением бизнеса, предприниматели
            борются с&nbsp;ФНС и&nbsp;банками, доказывая, что не&nbsp;имеют отношения
            к&nbsp;отмыванию доходов, полученных преступным путем. Часто это происходит из-за того,
            что предпринимателям не&nbsp;известны критерии отбора банками и&nbsp;ФНС
            налогоплательщиков, подлежащих контролю.
          </p>
        </div>
        <div className="col-md-6">
          <p className={styles.Text}>
            Иногда это происходит из- за&nbsp;не&nbsp;правильного построения бизнес-процессов или
            не&nbsp;верно выбранной системы налогообложения.
          </p>
          <p className={styles.Text}>
            Я&nbsp;работаю и&nbsp;общаюсь с&nbsp;представителями малого и&nbsp;среднего бизнеса
            каждый день и&nbsp;давно сделала вывод: нехватка знаний, непонимание принципов работы с
            госструктурами&nbsp;&mdash; это основная проблема малого и&nbsp;среднего бизнеса.
          </p>
          <div className={styles.Author}>
            <div className={styles.AuthorInner}>
              <p className={styles.Position}>Директор компании BizHouse</p>
              <p className={styles.Name}>Дорош Евгения</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
