import React from "react"
import { Helmet } from "react-helmet"

import { Feedback } from "~/components/Feedback/Feedback"
import { More } from "~/components/More/More"
import { Partners } from "~/components/Partners/Partners"

import { Faq } from "./components/Faq/Faq"
import { Hero } from "./components/Hero/Hero"
import { Info } from "./components/Info/Info"
import { Services } from "./components/Services/Services"
import { Steps } from "./components/Steps/Steps"
import { Tariff } from "./components/Tariff/Tariff"

export const Accounting = () => (
  <>
    <Helmet>
      <title>Комплексное бухгалтерское обслуживание вашей компании</title>
      <meta
        name="description"
        content="Аутсорсинг бухгалтерских услуг – заводим первичку, ведем кадровый учет, взаимодействуем с ФНС и банками."
      />
    </Helmet>
    <Hero />
    <Steps />
    <Info />
    <Services />
    <Tariff />
    <Partners />
    <Faq />
    <More />
    <Feedback />
  </>
)
