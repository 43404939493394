import React from "react"
import { Helmet } from "react-helmet"

import { Feedback } from "~/components/Feedback/Feedback"
import { More } from "~/components/More/More"

import { Hero } from "./components/Hero/Hero"
import { Price } from "./components/Price/Price"

export const Contracts = () => (
  <>
    <Helmet>
      <title>Составление и анализ договоров</title>
      <meta name="description" content="Составление и юридическая экспертиза договоров." />
    </Helmet>
    <Hero />
    <Price />
    <More />
    <Feedback />
  </>
)
