import cn from "classnames"
import React from "react"

import { LazyImage } from "~/components/LazyImage/LazyImage"
import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className={styles.Wrapper}>
    <div className="row">
      <div className="col-md-6">
        <div className={styles.Info}>
          <Title type="h1" className={styles.Title}>
            О компании
          </Title>
          <Title type="h4">Мы работаем с 2010 года</Title>
          <p className={styles.Text}>
            Среди клиентов нашей компании можно встретить бизнес структуры самых разнообразных
            правовых форм: это и&nbsp;акционерные общества, ООО, различные товарищества,
            всевозможные некоммерческие организации, филиалы российских фирм
            и&nbsp;представительства иностранных юридических лиц. Также наши специалисты занимаются
            обслуживанием индивидуальных предпринимателей.
          </p>
        </div>
      </div>
      <div className={cn("col-md-6", styles.ImageWrapper)}>
        <div className={styles.Image}>
          <LazyImage src={image} />
        </div>
      </div>
    </div>
  </div>
)
