export const shuffle = (array) => {
  var i = array.length
  var j = 0
  var temp

  while (i--) {
    j = Math.floor(Math.random() * (i + 1))
    temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }

  return array
}
