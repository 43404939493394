import cn from "classnames"
import React, { useRef } from "react"
import { Link } from "react-router-dom"

import styles from "./index.module.css"

export const SlidingItem = ({ name, list, isActive, changeActiveItem }) => {
  const ref = useRef()

  const onClick = (idx) => {
    changeActiveItem()
  }

  return (
    <div className={cn(styles.Wrapper, { [styles.IsActive]: isActive })} onClick={onClick}>
      <div className={styles.Parent}>
        <span className={styles.Arrow}>
          <svg width="5" height="10" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.613 5.01L1.059.81A.42.42 0 00.472.76a.406.406 0 00-.053.578L3.75 5.272.419 9.207a.406.406 0 00.053.578.42.42 0 00.587-.053l3.554-4.197a.406.406 0 000-.526z" />
          </svg>
        </span>
        <span className={styles.Name}>{name}</span>
      </div>
      <div
        className={styles.ListWrapper}
        ref={ref}
        style={{ maxHeight: (isActive && ref.current && ref.current.scrollHeight) || "" }}
      >
        <div className={styles.List}>
          {list.map(({ title, link }, i) => (
            <React.Fragment key={i}>
              {link && (
                <div className={styles.Item}>
                  <Link
                    to={link}
                    onClick={(e) => e.stopPropagation()}
                    className={styles.ItemLink}
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}
