import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Prompt } from "react-router-dom"

export const Loc = () => {
  const location = useLocation()

  useEffect(() => {
    window.scroll(0, 0)
  }, [location])

  return <Prompt message={(loc) => loc.pathname !== location.pathname} />
}
