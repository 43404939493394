import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Price = () => (
  <div className={styles.Wrapper}>
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className={styles.Info}>
            <Title type="h3" className={styles.Title}>
              Сроки и стоимость официальной ликвидации:
            </Title>
            <div className={cn(styles.Item, styles.Term)}>
              <p className={styles.Subtitle}>Сроки:</p>
              <p className={styles.Text}>
                Вся процедура ликвидации может занять от 4 до 6 месяцев. Сроки ликвидации зависят не
                только от ФНС, но и от состояния бухгалтерского учета ООО и наличия кредиторов на
                момент ликвидации.
              </p>
            </div>
            <div className={cn(styles.Item, styles.Price)}>
              <p className={styles.Subtitle}>Стоимость:</p>
              <p className={styles.Text}>
                Стоимость данной процедуры крайне индивидуальна. При отсутствии кредиторов
                и&nbsp;претензий ФНС к&nbsp;сданной отчетности за&nbsp;период работы ООО:
              </p>
              <p className={cn(styles.Text, styles.PriceInfo)}>
                Стоимость данной процедуры не&nbsp;превышает 50&nbsp;тысяч рублей, с учетом
                нотариальных услуг.
              </p>
              <p className={styles.Text}>
                В&nbsp;случае возникновения необходимости восстановления учета и&nbsp;прохождения
                камеральных и выездных проверок со&nbsp;стороны ФНС, стоимость оговаривается
                индивидуально с&nbsp;каждым клиентом.
              </p>
            </div>
          </div>
        </div>
        <div className={cn("col-md-6", styles.ImageWrapper)}>
          <div className={styles.Image}>
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
