import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={styles.Service}>
        <div className={cn("row", styles.Row)}>
          <div className="col-md-6">
            <div className={styles.Info}>
              <Title type="h2" as="h1" className={styles.Title}>
                Составление исковых, апелляционных и кассационных заявлений
              </Title>
              <p className={styles.Text}>
                Всем известно, что отстоять свои права можно путем разрешения споров в суде. Все
                больше бизнесменов сталкиваются с условиями процессуального характера. Мы изучим все
                факты и доказательства и составим исковое заявление в суд любой инстанции.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.Image}>
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
