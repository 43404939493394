import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className="row">
        <div className="col-sm-6">
          <div className={styles.Info}>
            <Title type="h2" as="h1" className={styles.Title}>
              {"Бесплатный <span>офлайн-курс</span>"}
            </Title>
            <Title type="h4">для начинающих предпринимателей и&nbsp;руководителей компаний</Title>
            <div className="row">
              <p className="col-md-10">
                За&nbsp;1&nbsp;месяц вы&nbsp;поймете, как облегчить работу с&nbsp;банками, правильно
                оптимизировать расходы, понять принципы взаимодействия с&nbsp;органами и&nbsp;многое
                другое.
              </p>
            </div>
          </div>
        </div>
        <div className={cn("col-sm-6", styles.ImageWrapper)}>
          <div className={styles.Image}>
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
