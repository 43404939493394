import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image1 from "./assets/image1.svg"
import image2 from "./assets/image2.svg"
import styles from "./index.module.css"

export const Info = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={cn("row", styles.Row)}>
        <div className="col-md-6 order-md-2">
          <div className={styles.Text1}>
            <Title type="h3">{"Оптимизация <br />налогооблагаемой базы"}</Title>
            <p className={styles.Text}>
              Многие считают, что оптимизация&nbsp;&mdash; это что-то незаконное. Это не&nbsp;так.
              Мы&nbsp;не&nbsp;ждем окончания квартала, чтобы лишь рассчитать и&nbsp;озвучить налог
              к&nbsp;уплате. Уже в&nbsp;течение квартала мы&nbsp;вникаем в&nbsp;ситуацию
              и&nbsp;выносим на&nbsp;обсуждение с&nbsp;клиентом предложения по снижению налоговой
              нагрузки.
            </p>
            <p className={styles.Text}>
              Иногда, это перераспределение расходной части, иногда переход на&nbsp;иную систему
              налогообложения. В&nbsp;любом случае, нами будут предприняты все меры
              по&nbsp;оптимизации вашей налогооблагаемой базы.
            </p>
          </div>
        </div>
        <div className="col-md-6 order-md-1">
          <div className={styles.Image1}>
            <img src={image1} alt="" />
          </div>
        </div>
      </div>
      <div className={cn("row")}>
        <div className="col-md-6">
          <div className={styles.Text2}>
            <Title type="h3">{"Ответственность, <br />которой мы&nbsp;не&nbsp;боимся"}</Title>
            <p className={styles.Text}>
              По&nbsp;условиям нашего договора, мы&nbsp;несем полную ответственность
              за&nbsp;выполнение наших обязанностей. В&nbsp;случае начисления вам штрафов
              за&nbsp;некорректное ведение бухгалтерского и налогового учета, мы&nbsp;компенсируем
              их&nbsp;вам в&nbsp;100% размере.
            </p>
            <p className={styles.Text}>
              Также мы&nbsp;организовываем выездные проверки на&nbsp;своей территории, даем
              пояснения в процессе проверки, стараясь, минимально привлекать клиента, экономя его
              время и&nbsp;нервы.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className={styles.Image2}>
            <img src={image2} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
