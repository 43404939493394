import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import styles from "./index.module.css"

export const Way = () => {
  const items = [
    {
      title: "Через онлайн-сервис",
      text:
        "Если&nbsp;вы, как физическое лицо, являетесь клиентом ПАО &laquo;Сбербанк&raquo; или ПАО Банк &laquo;ФК&nbsp;Открытие&raquo;, мы&nbsp;можем зарегистрировать вас удаленно, без визита к&nbsp;нотариусу и&nbsp;выезда в&nbsp;ФНС. Госпошлину при таком способе регистрации уплачивать не&nbsp;нужно. После регистрации&nbsp;ИП вам будет открыт расчетный счет в&nbsp;банке, сервис которого вы&nbsp;выбрали.",
      term: "Срок регистрации&nbsp;&mdash; 3&nbsp;рабочих дня.",
    },
    {
      title: "Регистрация в&nbsp;налоговой",
      text:
        "В&nbsp;случае, если онлайн-регистрация вам не&nbsp;подходит, мы&nbsp;предоставляем услугу регистрации ООО &laquo;под ключ&raquo;. Мы&nbsp;сами передаем и&nbsp;получаем документы из&nbsp;ФНС. Госпошлина и&nbsp;услуги нотариуса в&nbsp;данном случае оплачиваются дополнительно.",
      term: "Срок регистрации&nbsp;&mdash; 5&nbsp;рабочих дней.",
    },
  ]
  return (
    <div className={styles.Wrapper}>
      <div className="container">
        <div className={styles.Intro}>
          <Title type="h3" className={styles.Title}>
            Выбор способа регистрации
          </Title>
          <p className={styles.Subtitle}>
            Зарегистрировать ООО с нашей помощью можно следующими способами:
          </p>
        </div>
        <div className={cn("row", styles.Row)}>
          {items.map(({ title, text, term }, i) => (
            <div className={cn("col-md-6", styles.Col)} key={i}>
              <div className={cn(styles.Item)}>
                <div className={styles.ItemInfo}>
                  <div className={styles.ItemHead}>
                    <div className={styles.ItemNumber}>0{i + 1}</div>
                    <p className={styles.ItemTitle} dangerouslySetInnerHTML={{ __html: title }} />
                  </div>
                  <p className={styles.Text} dangerouslySetInnerHTML={{ __html: text }} />
                  <p className={styles.Term} dangerouslySetInnerHTML={{ __html: term }} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
