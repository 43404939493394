import React from "react"
import { Helmet } from "react-helmet"

import { Benefit } from "./components/Benefit/Benefit"
import { Cost } from "./components/Cost/Cost"
import { Hero } from "./components/Hero/Hero"
import { Program } from "./components/Program/Program"
import { Reasons } from "./components/Reasons/Reasons"
import { Target } from "./components/Target/Target"
import { Warning } from "./components/Warning/Warning"

export const Course = () => (
  <>
    <Helmet>
      <title>Бесплатный курс</title>
      <meta
        name="description"
        content="Бесплатный офлайн-курс для предпринимателей и руководителей по оптимизации и развитию бизнеса."
      />
    </Helmet>
    <Hero />
    <Reasons />
    <Warning />
    <Target />
    <Program />
    <Benefit />
    <Cost />
  </>
)
