import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import styles from "./index.module.css"

export const Steps = () => {
  const items = [
    {
      title: "Принятие решения о&nbsp;ликвидации и&nbsp;назначение ликвидатора ООО",
      text:
        "Этот этап заключается в&nbsp;подготовке решение участника (протокол, если участников в&nbsp;ООО несколько) о&nbsp;начале процедуры ликвидации ООО, после чего нужно уведомить об&nbsp;этом ФНС. По&nbsp;истечение пяти рабочих дней после уведомления ФНС, она внесет в&nbsp;ЕГРЮЛ сведения о&nbsp;том, что процедура ликвидации начата.",
    },
    {
      title:
        "Подача объявления в&nbsp;&laquo;Вестник государственной регистрации&raquo; о&nbsp;ликвидации ООО.",
      text: "",
    },
    {
      title: "Ожидание 2-х месяцев со дня публикации",
      text:
        "По&nbsp;истечении двух месяцев со&nbsp;дня публикации сообщения о&nbsp;ликвидации ООО, ликвидатор может подать в&nbsp;ФНС промежуточный ликвидационный баланс. На&nbsp;его рассмотрение и&nbsp;принятие у&nbsp;ФНС есть пять рабочих дней.",
    },
    {
      title: "Выдача подтверждающего документа об&nbsp;ликвидации",
      text:
        "В&nbsp;случае, если промежуточный ликвидационный баланс принят ФНС, то&nbsp;ликвидатор подает окончательный ликвидационный баланс. С&nbsp;этого дня в&nbsp;течение пяти рабочих дней ФНС вносит сведения в&nbsp;ЕГРЮЛ об&nbsp;исключении юридического лица из&nbsp;реестра налогоплательщиков и&nbsp;выдает документ, подтверждающий этот факт.",
    },
  ]
  return (
    <div className={styles.Wrapper}>
      <div className="container">
        <div className={styles.Intro}>
          <Title type="h3" className={styles.Title}>
            Этапы официальной ликвидации:
          </Title>
        </div>
        <div className={styles.Row}>
          {items.map(({ title, text, term }, i) => (
            <div className={styles.Col} key={i}>
              <div className={cn(styles.Item)}>
                <div className={styles.ItemInfo}>
                  <div className={styles.ItemHead}>
                    <div className={styles.ItemNumber}>0{i + 1}</div>
                    <p className={styles.ItemTitle} dangerouslySetInnerHTML={{ __html: title }} />
                  </div>
                  {text && <p className={styles.Text} dangerouslySetInnerHTML={{ __html: text }} />}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
