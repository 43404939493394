import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import cross from "./assets/cross.svg"
import styles from "./index.module.css"

export const Warning = () => {
  const items = [
    {
      title: "Я&nbsp;&mdash; не&nbsp;лектор",
      text:
        "На&nbsp;этих занятиях я&nbsp;не&nbsp;буду зачитывать вам статьи из&nbsp;налогового и&nbsp;гражданского кодекса, слушая которые можно уснуть.",
    },
    {
      title: "Я&nbsp;&mdash; не&nbsp;менеджер",
      text:
        "Я&nbsp;ничего не&nbsp;продаю, не&nbsp;рекламирую никакую услугу или сервис для бизнеса.",
    },
    {
      title: "Я&nbsp;&mdash; не&nbsp;теоретик",
      text:
        "Который просто сотый раз пересказывает информацию о&nbsp;проблеме, с&nbsp;которой сам ни&nbsp;разу в&nbsp;своей жизни не&nbsp;столкнулся&nbsp;и, уж&nbsp;тем более, не&nbsp;решил&nbsp;ее.",
    },
    {
      title: "Бесплатный курс",
      text:
        "Мне не&nbsp;нужны от&nbsp;вас никакие членские взносы, я&nbsp;не&nbsp;соби- раюсь продавать вам никакие сертификаты, которые можно повесить в&nbsp;рамку.",
    },
  ]

  return (
    <div className={cn("container", styles.Container)}>
      <div className={styles.Wrapper}>
        <Title type="h3">Вы должны понимать:</Title>
        <div className={styles.Row}>
          {items.map(({ title, text }, i) => (
            <div className={styles.ItemWrapper} key={i}>
              <div className={styles.Item}>
                <div className={styles.ItemInner}>
                  <p className={styles.Title} dangerouslySetInnerHTML={{ __html: title }} />
                  <p className={styles.Text} dangerouslySetInnerHTML={{ __html: text }} />
                </div>
                <img src={cross} className={styles.Cross} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
