import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={styles.Service}>
        <div className={cn("row", styles.Row)}>
          <div className="col-md-6">
            <div className={styles.Info}>
              <Title type="h2" as="h1" className={styles.Title}>
                Составление договоров гражданско-правового характера
              </Title>
              <p className={styles.Text}>
                Договор гражданско-правового характера служит для определения результата работ,
                которые должен выполнить исполнитель, с которым не оформляются трудовые отношения.
                Оформляется договор ГПХ по правилам ГК РФ, а не Трудового кодекса РФ.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.Image}>
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
