import React from "react"
import { Helmet } from "react-helmet"

import { Ability } from "./components/Ability/Ability"
import { CollectData } from "./components/CollectData/CollectData"
import { Content } from "./components/Content/Content"
import { FinalStatements } from "./components/FinalStatements/FinalStatements"
import { Hero } from "./components/Hero/Hero"
import { Terms } from "./components/Terms/Terms"

export const Policy = () => (
  <>
    <Helmet>
      <title>Наша политика конфиденциальности</title>
      <meta name="description" content="Политика конфиденциальности компании Bizhouse." />
    </Helmet>
    <Hero />
    <Terms />
    <Content />
    <Ability />
    <CollectData />
    <FinalStatements />
  </>
)
