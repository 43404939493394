import React from "react"

import styles from "./index.module.css"

export const Mask = ({ children }) => (
  <>
    <svg width="0" height="0">
      <clipPath id="mask">
        <path d="M1.427 17.647v967.627H1921.25V17.647s-374.7-48.239-657.15 18.222c0 0-279.111 73.283-563.076 96C324.706 161.974 1.427 17.647 1.427 17.647z" />
      </clipPath>
    </svg>

    <div className={styles.Mask}>{children}</div>
  </>
)
