import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Documents = () => (
  <div className={styles.Wrapper}>
    <div className="container">
      <div className={cn("row", styles.Row)}>
        <div className="col-md-6">
          <div className={styles.Info}>
            <Title type="h3">Получение готовых документов</Title>
            <p className={styles.Text}>
              По&nbsp;факту исключения&nbsp;ИП из&nbsp;ЕГРИП, ФНС выдает заверенную выписку,
              подтверждающую этот факт.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className={styles.Image}>
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
