import cn from "classnames"
import React from "react"

import styles from "./index.module.css"

export const Title = ({
  children,
  as,
  type = "h2",
  beforeText,
  withoutMargin,
  className,
  ...props
}) => {
  const FullClassName = cn(className, {
    [styles.H1]: type === "h1",
    [styles.H2]: type === "h2",
    [styles.H3]: type === "h3",
    [styles.H4]: type === "h4",
  })

  const Component = as || type

  return (
    <Component
      className={FullClassName}
      dangerouslySetInnerHTML={{ __html: children }}
      {...props}
    />
  )
}
