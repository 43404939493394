export const tariff = [
  {
    name: "Услуги и IT",
    prices: [
      "3&nbsp;000&nbsp;₽ в&nbsp;месяц",
      "5&nbsp;000&nbsp;₽ в&nbsp;месяц",
      "10&nbsp;000&nbsp;₽ в&nbsp;месяц",
    ],
    turnover: ["До 500 000 руб.", "До 1 000 000 руб.", "Свыше 1 000 000 руб."],
    salary: ["1", "3", "5"],
  },
  {
    name: "Торговля",
    prices: [
      "5&nbsp;000&nbsp;₽ в&nbsp;месяц",
      "10&nbsp;000&nbsp;₽ в&nbsp;месяц",
      "20&nbsp;000&nbsp;₽ в&nbsp;месяц",
    ],
    turnover: ["До 3 000 000 руб.", "До 5 000 000 руб.", "Свыше 5 000 000 руб."],
    salary: ["3", "5", "7"],
  },
  {
    name: "Производство/Строительство",
    prices: [
      "15&nbsp;000&nbsp;₽ в&nbsp;месяц",
      "25&nbsp;000&nbsp;₽ в&nbsp;месяц",
      "50&nbsp;000&nbsp;₽ в&nbsp;месяц",
    ],
    turnover: ["До 5 000 000 руб.", "До 10 000 000 руб.", "Свыше 10 000 000 руб."],
    salary: ["5", "7", "10"],
  },
]
