import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "./index.css"

import { createElement } from "react"
import { render } from "react-dom"

import App from "~/components/App/"

render(createElement(App), document.getElementById("root"))
