export const services = [
  {
    type: "accounting",
    name: "Бухгалтерские услуги",
    list: [
      { title: "Бухгалтерское обслуживание", link: "/buhgalterskoe-obsluzhivanie" },
      {
        title: "Бизнес консультации",
        link: "/biznes-konsultacii",
      },
    ],
  },
  {
    type: "registration",
    name: "Регистрационные услуги",
    list: [
      { title: "Регистрация ООО, АО, ЧОО", link: "/registraciya-ooo" },

      { title: "Регистрация ИП", link: "/registraciya-ip" },

      { title: "Ликвидация ООО", link: "/likvidaciya-ooo" },

      { title: "Ликвидация ИП", link: "/likvidaciya-ip" },

      { title: "Подготовка пакета документов для регистрации/ликвидации ИП/ООО", link: "" },

      {
        title:
          "Приведение уставных документов ООО в&nbsp;соответствие с&nbsp;действующим законодательством",
        link: "",
      },

      { title: "Добавление ОКВЭД в&nbsp;гос. реестр", link: "" },

      { title: "Смена директора ООО", link: "" },

      { title: "Смена учредителя ООО", link: "" },

      { title: "Смена названия ООО", link: "" },
    ],
  },
  {
    type: "legal",
    name: "Юридические услуги",
    list: [
      { title: "Составление претензий", link: "/sostavlenie-pretenzij" },
      {
        title: "Составление жалоб и&nbsp;заявлений",
        link: "/zhaloby-i-zayavleniya",
      },
      {
        title: "Составление исковых, апелляционных и&nbsp;кассационных заявлений",
        link: "/iskovye-zayavleniya",
      },
      { title: "Составление и&nbsp;анализ договоров", link: "/sostavlenie-dogovorov" },
      { title: "Представительство в&nbsp;Арбитражном суде", link: "/predstavitelstvo-v-sude" },
      {
        title: "Представление интересов в&nbsp;государственных органах",
        link: "/predstavitelstvo-v-organah",
      },
    ],
  },
  {
    type: "hr",
    name: "Кадровое сопровождение",
    list: [
      { title: "Составление штатного расписания ", link: "/shtatnoe-raspisanie" },
      { title: "Составление трудовых договоров ", link: "/trudovoj-dogovor" },
      { title: "Составление договоров гражданско-правового характера", link: "/dogovor-gph" },
      {
        title: "Составление правил внутреннего трудового распорядка",
        link: "/trudovoj-rasporyadok",
      },
      {
        title: "Составление договоров о материальной ответственности",
        link: "/dogovor-materialnoj-otvetstvennosti",
      },
      { title: "Представительство в трудовой инспекции", link: "/trudovaya-inspekciya" },
      { title: "Представительство в прокуратуре", link: "/prokuratura" },
    ],
  },
]
