import cn from "classnames"
import React from "react"

import anketa from "~/assets/anketa.pdf"
import { Title } from "~/components/Title/Title"

import styles from "./index.module.css"

export const Cost = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <Title type="h3">Стоимость и&nbsp;условия участия</Title>
      <div className="row">
        <div className="col-md-7">
          <div className={styles.Image}>
            <svg fill="none" viewBox="0 0 666 662" xmlns="http://www.w3.org/2000/svg">
              <circle opacity=".3" cx="74" cy="534" r="9" fill="#396CAA" />
              <circle opacity=".3" cx="377" cy="653" r="9" fill="#396CAA" />
              <circle cx="657" cy="325" r="9" className={styles.Circle} fill="#396CAA" />
              <circle opacity=".3" cx="329.5" cy="327.5" r="282" stroke="#396CAA" strokeWidth="5" />
              <circle opacity=".1" cx="330.5" cy="330.5" r="328" stroke="#396CAA" strokeWidth="5" />
              <circle cx="332.5" cy="326.5" r="221.5" fill="#396CAA" />
              <circle cx="559" cy="92" r="9" className={styles.Circle} fill="#396CAA" />
              <circle opacity=".3" cx="222" cy="19" r="9" fill="#396CAA" />
              <circle opacity=".3" cx="30" cy="196" r="9" fill="#396CAA" />
              <circle cx="612" cy="497" r="9" className={styles.Circle} fill="#396CAA" />

              <g opacity=".1" fill="#fff">
                <path d="M296.025 450.038a4.143 4.143 0 005.855 0l20.703-20.688-5.855-5.85-17.776 17.762-76.957-76.9 46.76-46.724-5.855-5.851-49.688 49.65a4.137 4.137 0 000 5.851l82.813 82.75zM460.412 289.433l-8.281-74.475a4.137 4.137 0 00-3.66-3.658l-74.531-8.275a4.176 4.176 0 00-3.383 1.188l-28.985 28.962 5.855 5.85 27.577-27.555 69.285 7.687 7.693 69.212-16.248 16.236-7.867-52.882a12.471 12.471 0 007.603-14.164 12.464 12.464 0 00-4.534-7.257 12.486 12.486 0 00-16.074.607 12.465 12.465 0 004.678 21.129l6.406 43.03-57.816-57.755a4.14 4.14 0 00-5.855 0L341.572 258l5.855 5.85 17.776-17.762 52.114 52.075-9.495 9.487 5.855 5.85 12.422-12.412c.234-.266.432-.563.588-.881l1.739 11.68-8.182 8.176 5.855 5.85 3.842-3.84 2.63 17.634a13.97 13.97 0 005.498 9.483 13.994 13.994 0 0019.912-3.271 13.974 13.974 0 002.171-10.742l-7.619-35.678 6.691-6.686a4.125 4.125 0 001.188-3.38zm-37.24-53.333a4.14 4.14 0 012.927 7.063 4.139 4.139 0 11-2.927-7.063zm28.872 100.757a5.697 5.697 0 01-4.735 6.719 5.698 5.698 0 01-4.331-1.105 5.704 5.704 0 01-2.205-3.885l-3.523-23.795 8.327-8.32 6.467 30.386z" />
                <path d="M269.114 337.027l-26.913 26.893 5.856 5.851 26.913-26.892-5.856-5.852zM281.944 349.853l-26.913 26.893 5.856 5.851 26.913-26.893-5.856-5.851zM283.604 373.848L267.87 389.57l5.856 5.852 15.734-15.723-5.856-5.851zM291.887 391.226l-11.182 11.173 5.856 5.851 11.181-11.173-5.855-5.851zM301.822 406.952l-8.28 8.274 5.856 5.851 8.28-8.274-5.856-5.851zM361.062 430.563a65.843 65.843 0 0039.17-12.934l29.862 29.839a12.93 12.93 0 0018.277 0 12.905 12.905 0 000-18.262l-30.554-30.531-.53.529a66.094 66.094 0 00-8.463-80.484 66.226 66.226 0 00-80.087-12.039 66.152 66.152 0 00-28.618 31.791 66.09 66.09 0 00-3.169 42.643 66.136 66.136 0 0023.603 35.667 66.22 66.22 0 0040.509 13.781zm81.454 4.493a4.646 4.646 0 011.007 5.057 4.654 4.654 0 01-2.513 2.511 4.655 4.655 0 01-3.554 0 4.633 4.633 0 01-1.507-1.006l-29.34-29.314a66.617 66.617 0 006.289-6.839l29.618 29.591zm-81.454-128.618a57.995 57.995 0 0132.205 9.762 57.936 57.936 0 0121.351 25.996 57.893 57.893 0 01-12.566 63.126 58.004 58.004 0 01-63.174 12.556 57.96 57.96 0 01-26.016-21.334 57.9 57.9 0 01-9.769-32.181 57.96 57.96 0 0116.997-40.941 58.048 58.048 0 0140.972-16.984z" />
                <path d="M361.063 414.012a49.716 49.716 0 0027.604-8.367 49.665 49.665 0 0018.301-22.282 49.617 49.617 0 00-10.771-54.108 49.702 49.702 0 00-25.441-13.589 49.728 49.728 0 00-28.708 2.826 49.68 49.68 0 00-22.299 18.286 49.623 49.623 0 00-8.374 27.584 49.687 49.687 0 0014.569 35.093 49.759 49.759 0 0035.119 14.557zm0-91.025a41.434 41.434 0 0123.004 6.973 41.39 41.39 0 0115.25 18.569 41.341 41.341 0 012.356 23.905 41.365 41.365 0 01-11.332 21.185 41.434 41.434 0 01-45.124 8.969 41.406 41.406 0 01-18.583-15.239 41.358 41.358 0 01-6.978-22.987 41.408 41.408 0 0112.141-29.243 41.469 41.469 0 0129.266-12.132z" />
                <path d="M365.203 380.912h-8.281a4.136 4.136 0 01-4.141-4.137H344.5a12.41 12.41 0 003.638 8.777 12.426 12.426 0 008.784 3.635v8.275h8.281v-8.275c3.295 0 6.454-1.307 8.784-3.635a12.41 12.41 0 003.638-8.777v-4.138c0-3.292-1.309-6.449-3.638-8.776a12.427 12.427 0 00-8.784-3.636h-8.281a4.144 4.144 0 01-4.141-4.138v-4.137a4.136 4.136 0 014.141-4.138h8.281a4.144 4.144 0 014.141 4.138h8.281a12.41 12.41 0 00-3.638-8.777 12.427 12.427 0 00-8.784-3.636v-8.275h-8.281v8.275a12.427 12.427 0 00-8.784 3.636 12.41 12.41 0 00-3.638 8.777v4.137c0 3.292 1.309 6.45 3.638 8.777a12.427 12.427 0 008.784 3.636h8.281a4.144 4.144 0 014.141 4.137v4.138a4.136 4.136 0 01-4.141 4.137zM232.703 302.3h27.66l27.903 19.914a4.113 4.113 0 002.406.773 4.17 4.17 0 002.248-.662 4.132 4.132 0 001.81-4.303l-3.147-15.722h23.933a20.74 20.74 0 0014.632-6.066 20.71 20.71 0 006.071-14.622v-57.924a20.71 20.71 0 00-6.071-14.622A20.74 20.74 0 00315.516 203h-82.813a20.737 20.737 0 00-14.632 6.066A20.71 20.71 0 00212 223.688v57.924a20.71 20.71 0 006.071 14.622 20.737 20.737 0 0014.632 6.066zm-12.422-78.612c0-3.292 1.309-6.45 3.639-8.777a12.425 12.425 0 018.783-3.636h82.813c3.294 0 6.454 1.308 8.783 3.636a12.405 12.405 0 013.639 8.777v57.924c0 3.292-1.309 6.45-3.639 8.777a12.425 12.425 0 01-8.783 3.636h-28.985a4.144 4.144 0 00-4.021 3.147 4.147 4.147 0 00-.037 1.818l2.071 10.431-20.463-14.606a4.122 4.122 0 00-2.393-.79h-28.985a12.425 12.425 0 01-8.783-3.636 12.405 12.405 0 01-3.639-8.777v-57.924z" />
                <path d="M232.703 285.75h86.953v-8.275h-82.812V266.44l8.281-11.03 9.109 12.135c.411.514.932.929 1.525 1.215a4.4 4.4 0 001.9.44 4.15 4.15 0 003.333-1.841l13.486-20.216 17.15 20.572a4.182 4.182 0 003.184 1.485c.13.008.26.008.39 0a4.138 4.138 0 003.229-2.11l20.704-37.238-7.238-4.013-17.772 31.958-16.831-20.191a4.072 4.072 0 00-3.408-1.506 4.142 4.142 0 00-3.222 1.837l-13.316 19.968-8.91-11.875a4.3 4.3 0 00-5.145-1.153 4.309 4.309 0 00-1.481 1.153l-4.968 6.62v-28.962h-8.282v57.924a4.138 4.138 0 004.141 4.138z" />
              </g>
            </svg>
            <span className={styles.Warning}>
              Курс абсолютно бесплатный, поэтому количество мест строго ограничено.
            </span>
          </div>
        </div>

        <div className="col-md-5">
          <div className={cn(styles.Text, styles.Text1)}>
            <p className={styles.StepTitle}>Для того, чтобы принять участие, нужно:</p>
            <div className={styles.Steps}>
              <p className={styles.Step}>
                Скачать и&nbsp;заполнить{" "}
                <a href={anketa} className={styles.Link} target="_blank" rel="noopener noreferrer">
                  анкету по&nbsp;ссылке
                </a>
              </p>
              <p className={styles.Step}>
                Отправить заполненную анкету на&nbsp;электронную почту{" "}
                <a href="mailTo:info@bizhouse.ru">info@bizhouse.ru</a> с&nbsp;пометкой
                &laquo;Обучение&raquo;.
              </p>
            </div>
          </div>
          <div className={cn(styles.Text, styles.Text2)}>
            <p className={styles.StepTitle}>
              Каждая анкета участника будет рассмотрена в&nbsp;индивидуальном порядке.
            </p>
          </div>
          <div className={cn(styles.Text, styles.Text3)}>
            <p className={styles.StepTitle}>
              Отобранные участники получат письмо с&nbsp;дальнейшей информацией на&nbsp;эл. Почту
              указанную в анкете.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)
