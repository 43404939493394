import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import image from "./assets/image.svg"
import styles from "./index.module.css"

export const Hero = () => (
  <div className="container">
    <div className={styles.Wrapper}>
      <div className={styles.Service}>
        <div className={cn("row", styles.Row)}>
          <div className="col-md-6">
            <div className={styles.Info}>
              <Title type="h2" as="h1" className={styles.Title}>
                Представительство в прокуратуре
              </Title>
              <p className={styles.Text}>
                Все споры с органами государственной власти лучше решать в досудебном порядке. Если
                дело дойдет до суда — высока вероятность его проиграть. Практика показывает: в 70%
                случаев суды издают решения в пользу государственных органов. Поэтому, если вы
                чувствуете, что за письмом или повесткой стоит что-то серьезное — лучше сразу найти
                представителя.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.Image}>
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
