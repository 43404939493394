import cn from "classnames"
import React, { useState } from "react"

import { LazyImage } from "~/components/LazyImage/LazyImage"
import { Title } from "~/components/Title/Title"

import image1 from "./assets/image1.svg"
import image2 from "./assets/image2.svg"
import play from "./assets/play.svg"
import { VideoPopup } from "./components/VideoPopup"
import styles from "./index.module.css"

export const Hero = () => {
  const [isVisible, showPopup] = useState(false)

  return (
    <>
      {isVisible && <VideoPopup closeHandler={() => showPopup(false)} />}
      <div className="container">
        <div className={styles.Wrapper}>
          <div className={cn("row", styles.Row)}>
            <div className="col-md-7">
              <div className={styles.Intro}>
                <Title type="h2" as="h1">
                  Комплексное бухгалтерское и&nbsp;юридическое обслуживание
                </Title>
                <p className={styles.Text}>
                  Занимайтесь развитием вашего бизнеса&nbsp;&mdash; <br />
                  все остальное сделаем&nbsp;мы!
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className={styles.ImageOne}>
                <LazyImage src={image1} />
              </div>
            </div>
          </div>
          <div className={cn("row", styles.Additional)}>
            <div className="col-md-5 order-md-2">
              <div className={styles.AdditionalInfo}>
                <Title type="h3">Больше, чем просто аутсорсинг</Title>
                <p className={styles.AdditionalText}>
                  Мы&nbsp;знаем, какие потребности есть у&nbsp;бизнеса и&nbsp;готовы полностью
                  их&nbsp;удовлетворить.
                </p>
                <div className={styles.Play} onClick={() => showPopup(true)}>
                  <div className={styles.PlayButton}>
                    <img src={play} className={styles.Arrow} alt="" />
                  </div>
                  <span className={styles.PlayText}>Смотреть видео</span>
                </div>
              </div>
            </div>
            <div className="col-md-7 order-md-1">
              <div className={styles.ImageTwo}>
                <LazyImage src={image2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
