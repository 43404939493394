import cn from "classnames"
import React from "react"

import { Title } from "~/components/Title/Title"

import styles from "./index.module.css"

export const Steps = () => {
  const items = [
    "Принимаем и&nbsp;заводим первичку.",
    "Ведём кадровый учет ваших сотрудников.",
    "Сами взаимодействуем с&nbsp;ФНС, банками и&nbsp;другими госорганами для решения любых вопросов.",
    "Также мы&nbsp;бесплатно предоставляем&nbsp;ПО для ведения учета и&nbsp;ключи ЭЦП для сдачи отчетности.",
  ]
  return (
    <div className={styles.Container}>
      <div className={styles.Wrapper}>
        <div className={cn("row", styles.Row)}>
          <div className="col-md-5">
            <div className={styles.Info}>
              <Title type="h3">Ведение учета</Title>
              <p className={styles.Text}>
                В&nbsp;штате каждого компании должен быть человек, исполняющий обязанности ведения
                налогового и&nbsp;бухгалтерского учета. Альтернатива бухгалтеру
                в&nbsp;штате&nbsp;&mdash; аутсорсинг бухгалтерских услуг.
              </p>
              <p className={styles.Text}>
                Главное преимущество аутсорсинга заключается в&nbsp;том, что вы&nbsp;не&nbsp;берете
                на&nbsp;себя обязанности, предусмотренные при заключении трудового договора.
                А&nbsp;именно: никаких отпусков и&nbsp;больничных, декретов и&nbsp;компенсаций.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <p className={styles.Subtitle}>
              Мы&nbsp;принимаем на&nbsp;себя все обязанности ведения бухгалтерии вашей компании:
            </p>
            <div className={styles.List}>
              {items.map((text, i) => (
                <div key={i} className={styles.Item}>
                  <div className={styles.ItemInner}>
                    <p className={styles.Number}>0{i + 1}</p>
                    <p className={styles.ItemText} dangerouslySetInnerHTML={{ __html: text }} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
